export type IBaseServiceImage = string | File | null

export enum GetServiceTypeEnum {
  ALL = 0,
  ENCO_SERVICE = 1,
  OTHER_SERVICE = 2,
}

export enum GetServiceContactStatusFilterEnum {
  ALL = 0,
  ACTIVE = 1,
  INACTIVE = 2,
}

export enum ServiceShowOrHideEnum {
  HIDE = 0,
  SHOW = 1,
}

export enum ServiceActiveStatusEnum {
  INACTIVE = 0,
  ACTIVE = 1,
}

export interface IGetServicesParams {
  serviceType?: GetServiceTypeEnum
  isActive?: GetServiceContactStatusFilterEnum
  txtSearch?: string
  serviceDisplayType?: GetServiceTypeEnum.ENCO_SERVICE | GetServiceTypeEnum.OTHER_SERVICE
}

export interface ISubmitServiceOzoneParams {
  Id: number // 0 = insert, id >0 = update
  Name: string
  MdServiceTypeId: number
  MdServiceCatId?: number
  icon: IBaseServiceImage
  CoverImgMobile: IBaseServiceImage
  CoverImgWeb: IBaseServiceImage
  Active: 0 | 1 // 0=False, 1=True,

  // non common value
  PropUnitName: string
  QuotationAbbr: string
  BookingFunction: ServiceShowOrHideEnum
  BookingBtnName: string
  MinDateBooking: number | null // date string, วันที่จองห่างจากวันปัจจุบันไม่น้อยกว่า
  MaxPostpone: number | null // number, จำนวนครั้งสูงสุดที่สามารถเลื่อนจองได้
  SponsorFunction: ServiceShowOrHideEnum // 0=False, 1=True, สถานะการเปิด Function Sponsor
  SponsorBtnName: string // string, Text ที่จะแสดงที่ปุ่ม Sponsor
  ServiceLocationFunc: ServiceShowOrHideEnum // 0=False, 1=True, สถานที่ให้บริการ (Service Location) ใช้เฉพาะ service ozone

  // fix value
  ContactFunction: 0
  MemberFunction: 0
  MemberBtnName: null
}

export interface ISubmitServiceStadiumParams {
  Id: number // 0 = insert, id >0 = update
  Name: string
  MdServiceTypeId: number
  MdServiceCatId?: number
  icon: IBaseServiceImage
  CoverImgMobile: IBaseServiceImage
  CoverImgWeb: IBaseServiceImage
  Active: 0 | 1 // 0=False, 1=True,

  // non common value
  PropUnitName: string
  QuotationAbbr: string
  MemberFunction: ServiceShowOrHideEnum // 0=False, 1=True, สถานะการเปิด Function สมัครสมาชิก
  MemberBtnName: string // string, Text ที่จะแสดงที่ปุ่มสมัครสมาชิก
  BookingFunction: ServiceShowOrHideEnum // 0=False, 1=True, สถานะการเปิด Function Booking
  BookingBtnName: string // string, Text ที่จะแสดงที่ปุ่ม Booking
  MinDateBooking: number | null // date string, วันที่จองห่างจากวันปัจจุบันไม่น้อยกว่า
  MaxPostpone: number | null // number, จำนวนครั้งสูงสุดที่สามารถเลื่อนจองได้

  SponsorFunction: ServiceShowOrHideEnum // 0=False, 1=True, สถานะการเปิด Function Sponsor
  SponsorBtnName: string // string, Text ที่จะแสดงที่ปุ่ม Sponsor

  // fix value
  ContactFunction: 0
  ServiceLocationFunc: 0 // 0=False, 1=True, สถานที่ให้บริการ (Service Location)
}

export interface ISubmitServiceOtherParams {
  Id: number // 0 = insert, id >0 = update
  Name: string
  MdServiceTypeId: number
  MdServiceCatId?: number
  AutoLink?: string
  icon: IBaseServiceImage
  CoverImgMobile: IBaseServiceImage
  CoverImgWeb: IBaseServiceImage
  Active: 0 | 1 // 0=False, 1=True,

  // non common value
  ContactFunction: 0 | 1 // 0=False, 1=True, สถานะเปิดให้ผู้ใช้ที่สนใจกรอกข้อมูลติดต่อกลับ

  // fix value
  PropUnitName: ''
  QuotationAbbr: ''
  MemberFunction: 0 // 0=False, 1=True, สถานะการเปิด Function สมัครสมาชิก
  MemberBtnName: null // string, Text ที่จะแสดงที่ปุ่มสมัครสมาชิก
  BookingFunction: 0 // 0=False, 1=True, สถานะการเปิด Function Booking
  BookingBtnName: null // string, Text ที่จะแสดงที่ปุ่ม Booking
  SponsorFunction: 0 // 0=False, 1=True, สถานะการเปิด Function Sponsor
  SponsorBtnName: null // string, Text ที่จะแสดงที่ปุ่ม Sponsor
  MinDateBooking: 0 // date string, วันที่จองห่างจากวันปัจจุบันไม่น้อยกว่า
  MaxPostpone: 0 // number, จำนวนครั้งสูงสุดที่สามารถเลื่อนจองได้
  ServiceLocationFunc: 0 // 0=False, 1=True, สถานที่ให้บริการ (Service Location)
}

export interface ISubmitServiceKioskParams {
  Id: number // 0 = insert, id >0 = update
  Name: string
  MdServiceTypeId: number
  MdServiceCatId?: number
  icon: IBaseServiceImage
  CoverImgMobile: IBaseServiceImage
  CoverImgWeb: IBaseServiceImage
  Active: 0 | 1 // 0=False, 1=True,

  // non common value
  PropUnitName: string
  BookingFunction: ServiceShowOrHideEnum // 0=False, 1=True, สถานะการเปิด Function Booking
  BookingBtnName: string // string, Text ที่จะแสดงที่ปุ่ม Booking
  MinDateBooking: number // date string, วันที่จองห่างจากวันปัจจุบันไม่น้อยกว่า
  MaxBookingPerDay: number
  MaxBookingPerWeek: number

  // fix value
  ContactFunction: 0
  ServiceLocationFunc: 0 // 0=False, 1=True, สถานที่ให้บริการ (Service Location)
  MaxPostpone: 0 // number, จำนวนครั้งสูงสุดที่สามารถเลื่อนจองได้
  QuotationAbbr: ''
  MemberFunction: 0 // 0=False, 1=True, สถานะการเปิด Function สมัครสมาชิก
  MemberBtnName: null // string, Text ที่จะแสดงที่ปุ่มสมัครสมาชิก
  SponsorFunction: 0 // 0=False, 1=True, สถานะการเปิด Function Sponsor
  SponsorBtnName: null // string, Text ที่จะแสดงที่ปุ่ม Sponsor
}

export type IMutationServiceParams =
  | ISubmitServiceOzoneParams
  | ISubmitServiceStadiumParams
  | ISubmitServiceOtherParams
  | ISubmitServiceKioskParams

export interface IMutationSetServiceRecNoParams {
  id: number // serviceId
  newRow: number
}

export interface IMutationSetServiceDisplayRecNoParams {
  id: number // serviceId
  newRow: number
  // MdDisplayTypeId: GetServiceTypeEnum.ENCO_SERVICE | GetServiceTypeEnum.OTHER_SERVICE
}

export interface IMutationSetServiceDisplayParams {
  id: number // serviceId
  MdDisplayTypeId: GetServiceTypeEnum.ENCO_SERVICE | GetServiceTypeEnum.OTHER_SERVICE
}

export interface IGetServiceSlotSelectParams {
  servicePropId?: number
  bookdate?: string
  itemId?: number
}

export interface ISaveServiceOrderBookingParams {
  id: number
  tbServiceId: number
  TbServicePropId: number
  bookingdate: string
  mdOrderTypeId?: number
  MdReceiptTypeId: number
  TbUserAddrId?: number
  remark?: string
  mdServiceLocationId?: number
  locationDesc?: string
  tbPromoDtIds?: string
  credit: number
  discount: number
  total: number
  net?: number
  orderItem: IOrderItemParams[]
}

export interface ISaveServiceOrderBookingFromAdminParams {
  id: number
  tbServiceId: number
  TbServicePropId: number
  bookingdate: string
  orderItem: IOrderItemParams[]

  firstname: ''
  lastname: ''
  email: ''
  tel: ''
  company: ''
  mdOrderTypeId: 1 //fix ยิง 1
  MdReceiptTypeId: 1 //fix ยิง 1
  TbUserAddrId: null //fix ยิง null
  remark: '' //fix ยิง "
  mdServiceLocationId: null //fix ยิง null
  locationDesc: '' //fix ยิง ""
  tbPromoDtId: null //ยกเลิกใช้งานฟิลด์นี้
  tbPromoDtIds: string //ตัวอย่างการใช้ PromoCode มากกว่า 1 Code >> "50,99,140" (ตัวอย่างมีการใช้งาน code ส่วนลด 3 โค้ด)  ***หมายเหตุเป็นฟิลด์ใหม่นะครับ
  credit: 0 // กรณี admin จองไม่ใช้งาน credit ให้ยิง 0
  discount: 0 // กรณี admin จองไม่ใช้งาน discount ให้ยิง 0
  total: number //ยอดเงินรวม
  net: 0 //fix เป็น 0
  paidAmt: number //ยอดเงินรวม
  mdBooinkgTypeId: 3 //ได้จาก [GET] GetMasterBookingType เฉพาะ web admin
  adminBookingRemark: string
  customerName: string
  customerSurname: string
}

export interface IOrderItemParams {
  tbItemId: number
  qty: number
  unitPrice: number
  mdSlotId?: number
  discount: number
}

export interface ICheckPromotionCodeParams {
  serviceId: number
  promoCode: string
}

export interface IGetEmployeePromotionParams {
  serviceId: number
}

export interface ISaveServiceRequestParams {
  id: number
  tbServiceId: number
  firstname: string
  lastname: string
  email: string
  tel: string
  mdOrderTypeId?: number
}
