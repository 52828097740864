import { Button, Col, Row } from 'antd'
import { styled } from 'goober'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { AppModal } from 'components/AppModal'
import { Txt } from 'components/Txt'
import { numberFormat } from 'helpers/number-helper'
import { DashIfNull } from 'helpers/string-helper'
import { backendPaths } from 'paths/backend-path-config'
import { useGetUserDetailQRY } from 'services/user/user-service'

type UserDetailModalType = {
  userId: number
  visible: boolean
  onClickEdit: () => void
  onCloseModal: () => void
}

export const UserDetailModal = (props: UserDetailModalType) => {
  const { visible, onCloseModal, onClickEdit, userId } = props
  const { data: userDetail } = useGetUserDetailQRY(userId.toString(), !!userId)

  const displayUserName = useMemo(() => {
    if (!userDetail) return

    const { prefix, firstname, lastname } = userDetail
    return `${prefix}${firstname} ${lastname}`
  }, [userDetail])

  const userEditPath = useMemo(() => backendPaths.userEdit({ queryParam: { userId } }), [userId])
  const userTransferCreditPath = useMemo(() => backendPaths.userTransferCredit({ queryParam: { userId } }), [userId])

  return (
    <AppModal visible={visible} width={800}>
      <AppModal.Header onCloseClick={onCloseModal}>ข้อมูลผู้ใช้งาน</AppModal.Header>
      <AppModal.Body>
        <Row>
          <RowItem label="ชื่อ - นามสกุล" value={DashIfNull(displayUserName)}></RowItem>
        </Row>
        <Row>
          <RowItem label="อีเมล" value={DashIfNull(userDetail?.email)}></RowItem>
          <RowItem label="เบอร์โทรศัพท์" value={DashIfNull(userDetail?.tel.toString())}></RowItem>
        </Row>
        <Row>
          <RowItem label="กลุ่มผู้ใช้งาน" value={DashIfNull(userDetail?.userGrp)}></RowItem>
          <RowItem label="ชื่อโปรไฟล์" value={DashIfNull(userDetail?.profile)}></RowItem>
        </Row>
        <Row>
          <RowItem label="Account Type" value={DashIfNull(userDetail?.accountType)}></RowItem>
          <RowItem label="Logon" value={DashIfNull(userDetail?.logon)}></RowItem>
        </Row>
        <Row>
          <RowItem label="สถานะผู้ใช้งาน (Status)" value={userDetail?.active ? 'Active' : 'Inactive'}></RowItem>
          <RowItem label="Credit" value={`${numberFormat(userDetail?.credit ?? 0, 2)} บาท`}></RowItem>
        </Row>
        <Row>
          <RowItem label="เป็นบุคคลภายใน/ภายนอก PTT Group" value={userDetail?.isPttRelate ? 'ใช่' : 'ไม่ใช่'}></RowItem>
        </Row>
      </AppModal.Body>
      <AppModal.Footer>
        {!!userDetail?.credit && (
          <Link to={userTransferCreditPath}>
            <Button type="primary" onClick={onClickEdit}>
              โอน Credit
            </Button>
          </Link>
        )}
        <Link to={userEditPath}>
          <Button onClick={onClickEdit}>แก้ไขข้อมูล</Button>
        </Link>
      </AppModal.Footer>
    </AppModal>
  )
}

const LabelContainer = styled('div')`
  margin-bottom: 8px;
`

const ValueContainer = styled('div')`
  margin-bottom: 24px;
`

type RowItemType = {
  label: string
  value: string
}

export const RowItem = (props: RowItemType) => {
  return (
    <Col xs={24} md={12} style={{ paddingRight: '16px' }}>
      <LabelContainer>
        <Txt ag="body16">{props.label}</Txt>
      </LabelContainer>
      <ValueContainer>
        <Txt ag="header24">{props.value}</Txt>
      </ValueContainer>
    </Col>
  )
}
