import { faCheckCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Divider, Form, Input, Row } from 'antd'
import { styled } from 'goober'
import { PropsWithChildren, useCallback, useMemo } from 'react'
import { NumberFormatValues, NumericFormat } from 'react-number-format'

import bgBlur from 'assets/images/cover-blue.png'
import { ItemQtyInput } from 'components/ItemQtyInput'
import { Txt } from 'components/Txt'
import { numberFormat } from 'helpers/number-helper'
import { mediaQuery } from 'helpers/responsive-helper'
import { IGetServiceSlotSelectResponse } from 'services/enco-service/enco-service-response'
import { IGetServiceItemResponse } from 'services/item-management/item-management-response'
import theme from 'theme/goober'

import { SectionTitle } from './OrderDetailForm'

const calculateItemTotalPrice = (items: ItemWithQty[], slots: IGetServiceSlotSelectResponse[]) => {
  let total = 0
  items.forEach((item) => (total += item.pricePerUnit * item.quantity))
  slots.forEach((slot) => (total += slot.price))
  return total
}

const calculateDiscountPercent = (percent: number, price: number, amount: number) => {
  const totalPrice = price * amount
  const discount = totalPrice * (percent / 100)
  return discount
}

const calculateItemDiscount = (items: ItemWithQty[]) => {
  let total = 0
  items.forEach((item) => (total += calculateDiscountPercent(item.item.discount, item.pricePerUnit, item.quantity)))
  return total
}

const calculateSlotDiscount = (slots: IGetServiceSlotSelectResponse[]) => {
  let total = 0
  slots.forEach((slot) => (total += slot.discountAmt))
  return total
}

const Icon = styled(FontAwesomeIcon)`
  color: ${theme.color.gray};
  cursor: pointer;
  font-size: 18px;
  margin-left: 12px;
`
const ActionContainer = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${mediaQuery.sm} {
    justify-content: flex-end;
  }
`
const NameContainer = styled(Col)`
  margin-top: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

interface ItemQtyProp {
  value?: ItemWithQty
  onDelete: (item: ItemWithQty) => void
  onChange?: (value: ItemWithQty) => void
  isShowTotalPrice?: boolean
  editPricePerUnit?: boolean
}

export const ItemQty = (props: ItemQtyProp) => {
  const { value: itemQty, onDelete, isShowTotalPrice, editPricePerUnit, onChange } = props
  const { item, quantity, pricePerUnit } = itemQty ?? {}

  const handleClickDelete = useCallback(() => {
    if (itemQty) {
      onDelete(itemQty)
    }
  }, [onDelete, itemQty])

  const handleQuantityChange = useCallback(
    (value: number) => {
      if (itemQty) {
        onChange?.({ ...itemQty, quantity: value })
      }
    },
    [itemQty, onChange],
  )

  const handlePricePerUnitChange = useCallback(
    (value: NumberFormatValues) => {
      if (itemQty) {
        onChange?.({ ...itemQty, pricePerUnit: value.floatValue ?? 0 })
      }
    },
    [itemQty, onChange],
  )

  const renderPricePerUnit = useMemo(() => {
    if (!isShowTotalPrice && !editPricePerUnit) return

    if (editPricePerUnit) {
      return (
        <NumericFormat
          value={pricePerUnit}
          inputMode="numeric"
          decimalScale={0}
          thousandSeparator=","
          allowNegative={false}
          allowLeadingZeros
          onValueChange={handlePricePerUnitChange}
          customInput={Input}
          style={{ maxWidth: 100 }}
        />
      )
    }

    if (isShowTotalPrice) {
      return (
        <Txt ag="body24" style={{ marginRight: 4 }}>
          {numberFormat(pricePerUnit)} บาท
        </Txt>
      )
    }
  }, [editPricePerUnit, handlePricePerUnitChange, isShowTotalPrice, pricePerUnit])

  return (
    <Col span={24}>
      <Row justify="space-between" align="middle">
        <NameContainer xs={24} sm={8}>
          <Txt style={{ cursor: 'context-menu' }} title={`${item?.name} (${item?.encoPrice} บาท)`} ag="body24">
            {item?.name} ({item?.encoPrice} บาท)
          </Txt>
        </NameContainer>
        <ActionContainer xs={24} sm={16}>
          <Row gutter={8}>
            <Col>
              <ItemQtyInput value={quantity} onChange={handleQuantityChange} />
            </Col>
            <Col>{renderPricePerUnit}</Col>
          </Row>
          <Icon icon={faTrash} onClick={handleClickDelete} />
        </ActionContainer>
      </Row>
      <Row>
        <Divider style={{ margin: '12px 0', border: 'none', borderTop: `1px solid ${theme.color.grayBackground}` }} />
      </Row>
    </Col>
  )
}

const Container = styled(Row)`
  overflow-y: auto;
  height: 600px;
`

const ItemContainer = styled(Col)`
  margin-bottom: 12px;
  padding: 16px;
`

const ImageContainer = styled.div`
  position: relative;
  width: 168px;
  height: 140px;
`

const Item = styled('img')`
  width: 168px;
  height: 140px;
  border-radius: 8px;
  object-fit: cover;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  cursor: pointer;
`

const ItemNameContainer = styled.div`
  margin-top: 12px;
`

const CheckIcon = styled(FontAwesomeIcon)`
  top: 8px;
  right: 0px;
  position: absolute;
  color: ${theme.color.lightBluePrimary};
  cursor: pointer;
  font-size: 30px;
  margin-right: 12px;
  border-radius: 50px;
  background-color: ${theme.color.white};
  border: 2px solid ${theme.color.white};
`

const TotalItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  border-bottom: 1px solid ${theme.color.grayBackground};
`

export interface ItemWithQty {
  item: IGetServiceItemResponse
  quantity: number
  pricePerUnit: number
}

interface ItemSelectorProps {
  masterItems: IGetServiceItemResponse[]
  onChange?: (value: ItemWithQty[]) => void
  value?: ItemWithQty[]
  selectedSlots?: IGetServiceSlotSelectResponse[]
}

export const ItemSelector = (props: PropsWithChildren<ItemSelectorProps>) => {
  const { masterItems, onChange, children, value: valueProps = [], selectedSlots = [] } = props

  const findItem = useCallback(
    (item: IGetServiceItemResponse) => {
      return valueProps.find((selected) => selected.item.id === item.id)
    },
    [valueProps],
  )

  const handleChange = useCallback(
    (values: ItemWithQty[]) => {
      onChange?.(values)
    },
    [onChange],
  )

  const onClickItem = useCallback(
    (item: IGetServiceItemResponse) => {
      const existed = findItem(item)
      let newSelectedList: ItemWithQty[] = []
      if (existed) {
        newSelectedList = valueProps.filter((selected) => item.id !== selected.item.id) || []
      } else {
        newSelectedList = [...valueProps, { item, quantity: 1, pricePerUnit: item.encoPrice }]
      }

      handleChange(newSelectedList)
    },
    [valueProps, findItem, handleChange],
  )

  const handleDeleteItem = useCallback(
    (item: ItemWithQty) => {
      const newSelectedList = valueProps.filter((selected) => item.item.id !== selected.item.id) || []
      handleChange(newSelectedList)
    },
    [valueProps, handleChange],
  )

  return (
    <Row gutter={24}>
      <Col span={24} xl={{ span: 14 }} style={{ marginBottom: 16 }}>
        <SectionTitle txt="รายการเพิ่มเติม" centered />
        <Container gutter={24}>
          {masterItems.map((item) => (
            <ItemContainer key={item.id} span={24} xs={24} sm={12} lg={8}>
              <ImageContainer onClick={() => onClickItem(item)}>
                {findItem(item) && <CheckIcon icon={faCheckCircle} />}
                <Item src={item.picture || bgBlur}></Item>
              </ImageContainer>
              <ItemNameContainer>
                <Txt style={{ cursor: 'context-menu' }} title={item.displayName} ag="body28" color="lightBluePrimary">
                  {item.displayName}
                </Txt>
              </ItemNameContainer>
            </ItemContainer>
          ))}
        </Container>
      </Col>
      <Col span={24} xl={{ span: 10 }} style={{ marginBottom: 16 }}>
        <SectionTitle txt="รายการที่เลือก" centered />

        <Form.List name="items">
          {(fields) => {
            return fields.map((field) => {
              return (
                <Form.Item key={field.key} name={[field.name]} noStyle>
                  <ItemQty onDelete={handleDeleteItem} />
                </Form.Item>
              )
            })
          }}
        </Form.List>
        <TotalItemContainer>
          <Txt ag="header30" color="grayPrimaryText">
            รวม {numberFormat(calculateItemTotalPrice(valueProps, selectedSlots))} บาท
          </Txt>
          <Txt ag="header30" color="grayPrimaryText">
            ส่วนลด {numberFormat(calculateSlotDiscount(selectedSlots) + calculateItemDiscount(valueProps))} บาท
          </Txt>
        </TotalItemContainer>
        {children}
      </Col>
    </Row>
  )
}
