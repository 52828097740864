import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputNumber } from 'antd'
import { valueType } from 'antd/lib/statistic/utils'
import { styled } from 'goober'
import { useCallback } from 'react'

import theme from 'theme/goober'

const CustomAddonButton = styled.button`
  width: 32px;
  height: 32px;
  padding: 4px;
  cursor: pointer;
  border: none;
  background: none;
`

const CustomNumberInput = styled(InputNumber)`
  width: 120px;
  .ant-input-number-handler-wrap {
    display: none;
    padding: 0px 4px;
  }
  .ant-input-number-group-addon:first-child {
    border: none;
    padding: 0;
  }
  .ant-input-number-group-addon:last-child {
    border: none;
    padding: 0;
  }
`
const Icon = styled(FontAwesomeIcon)`
  color: ${theme.color.grayPrimaryText};
  cursor: pointer;
  opacity: 0.2;
  font-size: 12px;
`

interface ItemQtyInputProps {
  onChange?: (value: number) => void
  value?: number
}

export const ItemQtyInput = (props: ItemQtyInputProps) => {
  const { onChange, value: valueProp = 1 } = props

  const handleValueChange = useCallback(
    (v: valueType) => {
      const val = Number(v)
      if (v === null) {
        onChange?.(1)
      } else {
        onChange?.(val)
      }
    },
    [onChange],
  )

  const onIncrease = () => {
    handleValueChange(valueProp + 1)
  }

  const onDecrease = () => {
    if (valueProp > 1) handleValueChange(valueProp - 1)
  }

  return (
    <CustomNumberInput
      stringMode
      precision={0}
      min={1}
      max={999}
      addonBefore={
        <CustomAddonButton disabled={valueProp < 2} onClick={onDecrease}>
          <Icon fontSize={8} icon={faMinus} opacity={valueProp < 2 ? 0.2 : undefined} />
        </CustomAddonButton>
      }
      addonAfter={
        <CustomAddonButton onClick={onIncrease}>
          <Icon icon={faPlus} />
        </CustomAddonButton>
      }
      value={valueProp}
      defaultValue={1}
      onChange={handleValueChange}
    />
  )
}
