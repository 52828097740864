import { Button, Col, Form, Row, Select, Space } from 'antd'
import { useWatch } from 'antd/lib/form/Form'
import { useCallback, useMemo } from 'react'

import { NUMBER_MORE_THAN_ZERO, requiredCustomMessage } from '_backend/constants/form-rules'
import { AppModal } from 'components/AppModal'
import { Input } from 'components/Input'
import NumberInput from 'components/NumberInput'
import { Txt } from 'components/Txt'
import { IGeneratePromoParams } from 'services/promotion-code/promotion-code-param'

interface IModalServiceTypeProps {
  visible: boolean
  onDismiss: () => void
  onSubmit: (values: IGeneratePromoParams) => void
  hideInfo?: boolean
}

export const ModalCreatePromotionCode = (props: IModalServiceTypeProps) => {
  const { visible, onDismiss, onSubmit, hideInfo } = props
  const [form] = Form.useForm()
  const fixCode = useWatch('FixCode', form)
  const genDigit = useWatch('GenDigit', form)

  const onCloseClick = useCallback(() => {
    form.resetFields()
    onDismiss()
  }, [form, onDismiss])

  const defaultGenDigit = [
    { label: 1, value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 },
  ]
  const genDigitDisplay = useMemo(() => {
    return 'X'.repeat(genDigit)
  }, [genDigit])

  return (
    <AppModal visible={visible} destroyOnClose forceRender>
      <Form form={form} onFinish={onSubmit} layout="vertical" autoComplete="off">
        <AppModal.Header onCloseClick={onCloseClick}>สร้างโปรโมชั่นโค้ด</AppModal.Header>
        <AppModal.Body>
          <Row gutter={[24, 24]}>
            <Col span={10}>
              <Form.Item
                label="จำนวนโปรโมชั่นโค้ด"
                required
                name="CodeQty"
                rules={[...requiredCustomMessage('กรุณากรอกข้อมูล'), ...NUMBER_MORE_THAN_ZERO]}
              >
                <NumberInput />
              </Form.Item>
            </Col>
            <Col style={{ alignSelf: 'center', marginTop: 30 }}>
              <Txt ag="body20">โค้ด</Txt>
            </Col>
          </Row>

          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Form.Item
                name="GenDigit"
                required
                label="Generate Digit"
                rules={[...requiredCustomMessage('กรุณากรอกข้อมูล'), ...NUMBER_MORE_THAN_ZERO]}
              >
                <Select options={defaultGenDigit} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="FixCode"
                required
                label="Fix Code"
                extra={
                  <Txt ag="subtitle14">
                    Example Promotion Code: {fixCode}
                    <Txt ag="subtitle14" color="redPrimary">
                      {genDigitDisplay}
                    </Txt>
                  </Txt>
                }
                rules={[
                  {
                    type: 'string',
                    required: true,
                    message: 'โปรดกรอก FixCode',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          {!hideInfo && <Txt ag="body20">หน่วยที่ใช้ในการคำนวนส่วนลด 1 โค้ดเท่ากับการจองสนาม 1 ชั่วโมง</Txt>}
        </AppModal.Body>
        <AppModal.Footer>
          <Form.Item shouldUpdate>
            <Space size={24} direction="horizontal">
              <Button onClick={onDismiss}>ยกเลิก</Button>
              <Button type="primary" htmlType="submit">
                สร้างโค้ด
              </Button>
            </Space>
          </Form.Item>
        </AppModal.Footer>
      </Form>
    </AppModal>
  )
}
