import { IPageReportQueryParams } from '_backend/modules/report/ReportFilterModal'

import { generate } from './path-tools'

// export const example = generate<[route param type], [query param type]>([pathname])
const backend = generate<{}, {}>('/backend')
const homeBannerList = generate<{}, {}>(`${backend()}/home-banner`)
const serviceList = generate<{}, {}>(`${backend()}/services`)
const serviceEdit = generate<{ serviceId: string }, {}>(`${serviceList()}/:serviceId`)
const serviceEnco = generate<{}, {}>(`${serviceList()}/enco`)
const serviceEncoNew = generate<{}, {}>(`${serviceEnco()}/new`)
const serviceEncoEdit = generate<{ serviceId: number }, {}>(`${serviceEncoNew()}/:serviceId`)

const serviceKiosk = generate<{}, {}>(`${serviceList()}/kiosk`)
const serviceKioskEdit = generate<{ serviceId: number }, {}>(`${serviceKiosk()}/:serviceId`)
const serviceKioskPeriodList = generate<{ serviceId: number }, {}>(`${serviceKiosk()}/:serviceId/periods`)
const serviceKioskDayOffList = generate<{ serviceId: number }, {}>(`${serviceKiosk()}/:serviceId/day-off`)
const serviceKioskMemberManagementList = generate<{ serviceId: number }, {}>(`${serviceKiosk()}/:serviceId/members`)
const serviceKioskMemberManagementDetail = generate<{ serviceId: number; registerId: number }, {}>(
  `${serviceKiosk()}/:serviceId/members/:registerId`,
)
const serviceKioskMasterProductList = generate<{ serviceId: number }, {}>(
  `${serviceKiosk()}/:serviceId/master-products`,
)
const serviceKioskMasterProductNew = generate<{ serviceId: number }, {}>(
  `${serviceKiosk()}/:serviceId/master-products/new`,
)
const serviceKioskMasterProductEdit = generate<{ serviceId: number; productId: number }, {}>(
  `${serviceKiosk()}/:serviceId/master-products/:productId`,
)

const serviceEncoOrder = generate<{ serviceId: number }, {}>(`${serviceEncoNew()}/:serviceId/orders`)
const serviceEncoContent = generate<{ serviceId: number }, {}>(`${serviceEnco()}/:serviceId/content`)
const serviceOther = generate<{}, {}>(`${serviceList()}/other`)
const serviceOtherNew = generate<{}, {}>(`${serviceOther()}/new`)
const serviceOtherEdit = generate<{ serviceId: number }, {}>(`${serviceOther()}/:serviceId`)
const serviceOtherOrder = generate<{ serviceId: number }, {}>(`${serviceOther()}/:serviceId/orders`)
const serviceOrderList = generate<{ serviceId: number }, {}>(`${serviceList()}/:serviceId/orders`)
const serviceOrderBooking = generate<{ serviceId: number }, {}>(`${serviceList()}/:serviceId/orders/booking`)
const serviceOrderDetail = generate<{ serviceId: number; orderId: number }, {}>(
  `${serviceList()}/:serviceId/orders/:orderId`,
)
const userList = generate<{}, {}>(`${backend()}/users`)
const userNew = generate<{}, {}>(`${backend()}/users/new`)
const userEdit = generate<{}, {}>(`${backend()}/users/edit`)
const userTransferCredit = generate<{}, {}>(`${backend()}/users/transfer-credit`)
const prNewsList = generate<{}, {}>(`${backend()}/pr-news`)
const prNewsNew = generate<{}, {}>(`${backend()}/pr-news/new`)
const prNewsEdit = generate<{ prNewsId: string }, {}>(`${prNewsList()}/:prNewsId/edit`)
const suggestionList = generate<{}, {}>(`${backend()}/suggestions`)
const suggestionImage = generate<{ suggestionId: number }, {}>(`${backend()}/suggestions/:suggestionId`)
const reportList = generate<{}, {}>(`${backend()}/reports`)
const quotationList = generate<{}, {}>(`${backend()}/quotations`)
const quotationNew = generate<{}, { orderId: number }>(`${quotationList()}/new`)
const quotationEdit = generate<{ quotationId: number }, {}>(`${quotationList()}/:quotationId/edit`)
const invoiceList = generate<{}, {}>(`${backend()}/invoices`)
const invoiceNew = generate<{}, { quotationId: number }>(`${invoiceList()}/new`)
const invoiceEdit = generate<{ invoiceId: number }, {}>(`${invoiceList()}/:invoiceId/edit`)
const receiptList = generate<{}, {}>(`${backend()}/receipts`)
const receiptNew = generate<{}, { invoiceId: number }>(`${receiptList()}/new`)
const receiptEdit = generate<{ receiptId: number }, {}>(`${receiptList()}/:receiptId/edit`)
const itemManagementList = generate<{ serviceId: number }, {}>(`${serviceList()}/:serviceId/item-management`)
const itemManagementNew = generate<{ serviceId: number }, {}>(`${serviceList()}/:serviceId/item-management/new`)
const itemManagementEdit = generate<{ serviceId: number; itemId: number }, {}>(
  `${serviceList()}/:serviceId/item-management/:itemId/edit`,
)

const serviceEncoPropertyManagementList = generate<{ serviceId: number }, {}>(`${serviceList()}/:serviceId/properties`)
const serviceEncoPropertyManagementNew = generate<{ serviceId: number }, {}>(
  `${serviceList()}/:serviceId/properties/new`,
)
const serviceEncoPropertyManagementEdit = generate<{ serviceId: number; propertyId: number }, {}>(
  `${serviceList()}/:serviceId/properties/:propertyId/edit`,
)
const serviceEncoPropertyManagementDayOffList = generate<{ serviceId: number; propertyId: number }, {}>(
  `${serviceList()}/:serviceId/properties/:propertyId/day-off`,
)

const serviceEncoPromotionCodeList = generate<{ serviceId: number }, {}>(`${serviceList()}/:serviceId/promotion-code`)
const serviceEncoPromotionNew = generate<{ serviceId: number }, {}>(`${serviceList()}/:serviceId/promotion-code/new`)
const serviceEncoPromotionEdit = generate<{ serviceId: number; promotionId: number }, {}>(
  `${serviceList()}/:serviceId/promotion-code/:promotionId/edit`,
)
const memberAndSponsor = generate<{ serviceId: number }, {}>(`${serviceList()}/:serviceId/member-and-sponsor`)
const report = generate<{ reportId: number }, IPageReportQueryParams>(`${backend()}/reports/:reportId`)
const permissionSettingList = generate<{}, {}>(`${backend()}/permission-settings`)
const permissionSettingNew = generate<{}, {}>(`${permissionSettingList()}/new`)
const permissionSettingEdit = generate<{ groupId: number }, {}>(`${permissionSettingList()}/:groupId/edit`)

export const backendPaths = {
  backend,
  serviceList,
  serviceEdit,
  serviceEncoNew,
  serviceEncoEdit,
  serviceEncoOrder,
  serviceEncoContent,
  serviceOtherNew,
  serviceOtherEdit,
  serviceOtherOrder,
  serviceOrderList,
  serviceOrderBooking,
  serviceOrderDetail,

  serviceKioskEdit,
  serviceKioskPeriodList,
  serviceKioskDayOffList,
  serviceKioskMemberManagementList,
  serviceKioskMemberManagementDetail,
  serviceKioskMasterProductList,
  serviceKioskMasterProductNew,
  serviceKioskMasterProductEdit,

  homeBannerList,
  userList,
  userNew,
  userEdit,
  userTransferCredit,
  prNewsList,
  prNewsNew,
  prNewsEdit,
  suggestionList,
  suggestionImage,
  reportList,
  quotationList,
  quotationNew,
  quotationEdit,
  invoiceList,
  invoiceNew,
  invoiceEdit,
  receiptList,
  receiptNew,
  receiptEdit,
  itemManagementList,
  itemManagementNew,
  itemManagementEdit,
  serviceEncoPropertyManagementList,
  serviceEncoPropertyManagementNew,
  serviceEncoPropertyManagementDayOffList,
  serviceEncoPropertyManagementEdit,
  serviceEncoPromotionCodeList,
  serviceEncoPromotionNew,
  serviceEncoPromotionEdit,
  memberAndSponsor,
  report,
  permissionSettingList,
  permissionSettingNew,
  permissionSettingEdit,
}
