import { Card, Col, Form, notification, Radio, Row } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ISearchAutoCompleteOption, SearchAutoComplete } from '_backend/components/SearchAutocomplete'
import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { requiredCustomMessage } from '_backend/constants/form-rules'
import { AccountTypeEnum, UserTypeEnum } from '_backend/constants/user-management-constants'
import { backendPaths } from 'paths/backend-path-config'
import { IUserSaveParams } from 'services/user/user-param'
import { IGetEmployeeResponse } from 'services/user/user-response'
import { useGetAccountTypeListQRY, useGetEncoEmployeeQRY, useSaveUserDetailMTT } from 'services/user/user-service'

import { IUserDetailFormValue, UserDetailForm } from './UserDetailForm'

const convertToOptions = (datas: IGetEmployeeResponse[]): ISearchAutoCompleteOption[] => {
  return datas.map((data) => ({
    text: `${data.prefix}${data.firstname} ${data.lastname}`,
    value: data.empcode,
  }))
}

const convertToFormValues = (employee: IGetEmployeeResponse): IUserDetailFormValue => {
  const { prefix, firstname, lastname, email, tel, profile, logon } = employee
  return {
    prefix: prefix,
    firstName: firstname,
    lastName: lastname,
    email: email,
    tel,
    profile,
    logon,
    active: true,
    isPttRelate: 0,
  }
}

export const PageUserNew = () => {
  const navigate = useNavigate()

  const [keyword, setKeyword] = useState('')
  const [selectedEmployee, setSelectedEmployee] = useState<IGetEmployeeResponse>()
  const [userType, setUserType] = useState<UserTypeEnum>(UserTypeEnum.enco)

  const [form] = Form.useForm()

  const { data: employeeList = [] } = useGetEncoEmployeeQRY({ txtSearch: keyword })
  const { data: accountTypeList = [] } = useGetAccountTypeListQRY()

  const { mutateAsync: saveUser } = useSaveUserDetailMTT()

  const resetFormField = useCallback(() => {
    form.resetFields([
      'prefix',
      'firstName',
      'lastName',
      'email',
      'company',
      'logon',
      'tel',
      'userGroupId',
      'active',
      'accountType',
      'profile',
    ])
  }, [form])

  const handleTypeChange = useCallback(
    (type: UserTypeEnum) => {
      resetFormField()
      setUserType(type)
    },
    [resetFormField],
  )

  const handleSaveSuccess = useCallback(() => {
    navigate(backendPaths.userList())
    notification.success({ message: 'สำเร็จ', description: 'บันทึกข้อมูลผู้ใช้งานสำเร็จ', duration: 2 })
  }, [navigate])

  const handleSubmitForm = useCallback(
    async (values: IUserDetailFormValue) => {
      const userForSave: IUserSaveParams = {
        Userid: 0,
        Prefix: values.prefix,
        Firstname: values.firstName,
        Lastname: values.lastName,
        Email: values.email,
        Logon: values.logon,
        Tel: values.tel,
        UserTypeId: userType,
        MdAccountTypeId: 1,
        Profile: values.profile,
        UserGrpId: values.userGroupId,
        Active: values.active ? 1 : 0,
        isPttRelate: values.isPttRelate ?? 0,
      }
      if (selectedEmployee?.company) userForSave.Company = selectedEmployee.company
      if (selectedEmployee?.empcode) userForSave.Empcode = selectedEmployee.empcode
      await saveUser(userForSave, { onSuccess: handleSaveSuccess })
    },
    [saveUser, selectedEmployee, userType, handleSaveSuccess],
  )

  const handleSearchChange = useCallback((value: string) => {
    setKeyword(value)
  }, [])

  const handleChangeSelectEmployee = useCallback(
    (value: string) => {
      if (value) {
        const item = employeeList.find((emp) => emp.empcode === value)
        if (item) {
          form.setFieldsValue(convertToFormValues(item))
          setSelectedEmployee(item)
        } else resetFormField()
      } else {
        resetFormField()
      }
    },
    [employeeList, form, resetFormField],
  )

  const enCoAccountType = useMemo(
    () => accountTypeList?.find((t) => t.id === AccountTypeEnum.EnCo)?.accountType,
    [accountTypeList],
  )

  useEffect(() => {
    form.setFieldsValue({ accountType: enCoAccountType })
  }, [enCoAccountType, form])

  return (
    <>
      <ContentHeader subTitle="เพิ่มผู้ใช้งาน" />
      <ContentBody max-width="686px">
        <Card>
          <Form
            layout="vertical"
            form={form}
            initialValues={{ userType: UserTypeEnum.enco, active: true, accountType: enCoAccountType }}
            onFinish={handleSubmitForm}
          >
            <Row>
              <Col sm={{ span: 12 }} span={24}>
                <Form.Item name="userType">
                  <Radio.Group buttonStyle="solid" onChange={(e) => handleTypeChange(e.target.value)}>
                    <Radio.Button value={UserTypeEnum.enco}>พนักงาน EnCo</Radio.Button>
                    <Radio.Button value={UserTypeEnum.bsa}>พนักงาน BSA</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            {userType === UserTypeEnum.enco && (
              <Row gutter={24} style={{ marginBottom: 24 }}>
                <Col span={24}>
                  <Form.Item
                    noStyle
                    name="empcode"
                    label="เพิ่มผู้ใช้งานผ่านจากค้นหา"
                    rules={requiredCustomMessage('กรุณาค้นหาและเลือกพนักงาน')}
                  >
                    <SearchAutoComplete
                      options={convertToOptions(employeeList || [])}
                      onSearch={handleSearchChange}
                      onChange={handleChangeSelectEmployee}
                      width="100%"
                      placeholder="ค้นหาจากรหัสพนักงาน / ชื่อ - นามสกุล"
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <UserDetailForm userType={userType} />
          </Form>
        </Card>
      </ContentBody>
    </>
  )
}
