import dayjs from 'dayjs'
import { styled } from 'goober'
import { Calendar as BaseCalendar, CalendarProps, dayjsLocalizer, Event } from 'react-big-calendar'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'

const WrapStyled = styled.div`
  .rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
    border-left: 1px solid #ddd;
  }
`

const localizer = dayjsLocalizer(dayjs)
interface AppBigCalendarProps<TEvent extends object = Event, TResource extends object = object>
  extends Omit<CalendarProps<TEvent, TResource>, 'localizer'> {}

export const AppBigCalendar = <TEvent extends object = Event, TResource extends object = object>(
  props: AppBigCalendarProps<TEvent, TResource>,
) => {
  const { ...restProps } = props
  return (
    <WrapStyled>
      <BaseCalendar localizer={localizer} {...restProps} />
    </WrapStyled>
  )
}
