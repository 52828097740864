import { useParams } from 'react-router-dom'

import { ReportCommon } from './ReportCommon'
import { ReportEncoStadiumSponsor } from './ReportEncoStadiumSponsor'

export const PageReport = () => {
  const { reportId: reportIdParam } = useParams()

  const reportId = Number(reportIdParam)

  if ([7].includes(reportId)) {
    return <ReportEncoStadiumSponsor />
  }

  return <ReportCommon />
}
