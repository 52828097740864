import { Button, Col, Form, Row } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { styled } from 'goober'
import { useCallback, useMemo } from 'react'

import { requiredCustomMessage } from '_backend/constants/form-rules'
import { Input, TextArea } from 'components/Input'
import { ISaveAddressParams } from 'services/address/address-params'
import { IGetAddrResponse } from 'services/address/address-response'

const ButtonContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
`

const CustomButton = styled(Button)`
  width: 100%;
  max-width: 343px;
  margin-bottom: 14px;
`

interface AddressFormProps {
  onSave: (params: ISaveAddressParams) => void
  onClose: () => void
  editAddr?: IGetAddrResponse
}

export const AddressForm = (props: AddressFormProps) => {
  const { onSave, onClose, editAddr } = props
  const [form] = useForm()

  const initialValues = useMemo(() => {
    if (editAddr)
      return {
        fullName: editAddr.fullname,
        tax: editAddr.tax,
        address: editAddr.address,
      }
    return undefined
  }, [editAddr])

  const handleFinishForm = useCallback(
    (values: any) => {
      onSave({
        id: editAddr?.id || 0,
        fullname: values.fullName,
        address: values.address,
        tax: values.tax,
      })
    },
    [onSave, editAddr],
  )

  const handleClickClose = useCallback(() => {
    form.resetFields()
    onClose()
  }, [form, onClose])

  return (
    <Row gutter={24}>
      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        style={{ width: '100%' }}
        onFinish={handleFinishForm}
      >
        <Col xs={24}>
          <Form.Item name="fullName" label="Full Name" rules={requiredCustomMessage('กรุณากรอกชื่อ')}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item
            name="tax"
            label="Tax Identification Number"
            rules={[...requiredCustomMessage('กรุณากรอกเลขประจำตัวผู้เสียภาษี')]}
          >
            <Input maxLength={13} />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item name="address" label="Address" rules={requiredCustomMessage('กรุณากรอกที่อยู่')}>
            <TextArea rows={4} autoSize={{ maxRows: 4, minRows: 4 }} />
          </Form.Item>
        </Col>
        <ButtonContainer>
          <Form.Item style={{ width: '100%', maxWidth: '343px', margin: 0 }}>
            <CustomButton htmlType="submit" type="primary">
              Save
            </CustomButton>
          </Form.Item>
          <CustomButton onClick={handleClickClose}>Close</CustomButton>
        </ButtonContainer>
      </Form>
    </Row>
  )
}
