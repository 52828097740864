import { Button, Col, DatePicker, Form, Radio, Row, Space } from 'antd'
import { RuleRender } from 'antd/lib/form'
import dayjs, { Dayjs } from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { chain } from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'

import { componentDisplayDateFormat } from '_backend/constants/date-format'
import { REQUIRED_RULES } from '_backend/constants/form-rules'
import { AppModal } from 'components/AppModal'
import { Input } from 'components/Input'
import { ISubmitKioskPeriodParams } from 'services/kiosk-period/kiosk-period-params'
import { useGetKioskPeriodListQRY } from 'services/kiosk-period/kiosk-period-service'

dayjs.extend(isBetween)

export interface IModalKioskPeriodFormValues extends Omit<ISubmitKioskPeriodParams, 'tbServiceId'> {}

type IDateRage = [Dayjs | undefined, Dayjs | undefined]

interface IInternalModalKioskPeriodFormValues
  extends Omit<IModalKioskPeriodFormValues, 'startdate' | 'enddate' | 'startBookingDate' | 'endBookingDate'> {
  dateRange: IDateRage
  bookingDateRange: IDateRage
}

const statusOptions = [
  {
    value: 1,
    label: 'Active',
  },
  {
    value: 0,
    label: 'Inactive',
  },
]

const isDateOverlapExist = (target: IDateRage, dateRanges: IDateRage[]): boolean => {
  let [targetStart, targetEnd] = target ?? []

  if (targetStart && targetEnd) {
    targetStart = dayjs(targetStart)
    targetEnd = dayjs(targetEnd)

    for (const dateRange of dateRanges) {
      const [start, end] = dateRange
      if (start && end) {
        const checkList = [
          dayjs(targetStart).isBetween(start, end, 'days', '[]'),
          dayjs(targetEnd).isBetween(start, end, 'days', '[]'),
          dayjs(start).isBetween(targetStart, targetEnd, 'days', '[]'),
          dayjs(end).isBetween(targetStart, targetEnd, 'days', '[]'),
        ]

        const isNotOverlap = checkList.some((e) => e)
        if (isNotOverlap) return true
      }
    }
  }

  return false
}

interface IModalKioskPeriodProps {
  serviceId: number
  visible: boolean
  initialValues?: Partial<IModalKioskPeriodFormValues>
  onDismiss: () => void
  onConfirm: (values: IModalKioskPeriodFormValues) => void
  onCancelKioskPeriodClick: (kioskPeriodId: number) => void
}
export const ModalKioskPeriod = (props: IModalKioskPeriodProps) => {
  const {
    serviceId,
    initialValues: preInitialValues = {},
    visible,
    onDismiss,
    onConfirm,
    onCancelKioskPeriodClick,
  } = props

  const [form] = Form.useForm<IInternalModalKioskPeriodFormValues>()
  const { data: kioskPeriods } = useGetKioskPeriodListQRY(Number(serviceId))

  const isEditMode = useMemo(() => {
    if (preInitialValues?.id) return true
    if (preInitialValues?.id === 0) return true
    return false
  }, [preInitialValues?.id])

  const initialValues = useMemo((): Partial<IInternalModalKioskPeriodFormValues> => {
    const {
      id,
      description = '',
      isActive = 1,
      startdate,
      enddate,
      startBookingDate,
      endBookingDate,
      ...restValues
    } = preInitialValues
    return {
      id: id ?? 0,
      description,
      isActive,
      dateRange: [startdate ? dayjs(startdate) : undefined, enddate ? dayjs(enddate) : undefined],
      bookingDateRange: [
        startBookingDate ? dayjs(startBookingDate) : undefined,
        endBookingDate ? dayjs(endBookingDate) : undefined,
      ],
      ...restValues,
    }
  }, [preInitialValues])

  const periodDateRangeExists = useMemo(() => {
    return chain(kioskPeriods ?? [])
      .filter((e) => e.id !== initialValues?.id)
      .map((e): IDateRage => {
        const { startdate, enddate } = e
        return [startdate ? dayjs(startdate) : undefined, enddate ? dayjs(enddate) : undefined]
      })
      .value()
  }, [initialValues?.id, kioskPeriods])

  const handleDismiss = useCallback(() => {
    form.resetFields()
    onDismiss()
  }, [form, onDismiss])

  const onSubmit = useCallback(
    (values: IInternalModalKioskPeriodFormValues) => {
      const [startdate, enddate] = values.dateRange
      const [startBookingDate, endBookingDate] = values.bookingDateRange
      onConfirm({
        ...values,
        id: preInitialValues?.id ?? 0,
        startdate: dayjs(startdate).format(componentDisplayDateFormat),
        enddate: dayjs(enddate).format(componentDisplayDateFormat),
        startBookingDate: dayjs(startBookingDate).format(componentDisplayDateFormat),
        endBookingDate: endBookingDate ? dayjs(endBookingDate).format(componentDisplayDateFormat) : null,
      })
      form.resetFields()
    },
    [form, onConfirm, preInitialValues],
  )

  const modalHeaderLabel = useMemo(() => {
    if (isEditMode) return 'แก้ไขรอบการขาย'
    return 'เพิ่มรอบการขาย'
  }, [isEditMode])

  const submitButtonLabel = useMemo(() => {
    return 'บันทึก'
  }, [])

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  const checkBookingDateRule = useCallback<RuleRender>(({ getFieldValue }) => {
    return {
      validator: (_, dateRange) => {
        const [startBookingDate, endBookingDate] = getFieldValue('bookingDateRange') ?? []
        const [startDate, endDate] = dateRange ?? []

        if (startDate && startBookingDate && dayjs(startBookingDate).isAfter(dayjs(startDate))) {
          return Promise.reject('วันที่เริ่มขาย ต้องมากกว่าเท่ากับ วันที่เริ่มจองได้')
        }

        if (endDate && endBookingDate && dayjs(endBookingDate).isAfter(dayjs(endDate))) {
          return Promise.reject('วันที่สิ้นสุดขาย ต้องมากกว่าเท่ากับ วันที่สิ้นสุดการจอง')
        }

        return Promise.resolve()
      },
    }
  }, [])

  const checkDateOverlapRule = useCallback<RuleRender>(() => {
    return {
      validator: (_, dateRange) => {
        const isOverlap = isDateOverlapExist(dateRange, periodDateRangeExists)
        if (isOverlap) {
          return Promise.reject('วันที่เริ่มขาย - วันที่สิ้นสุดขาย ซ้อนทับกับรายการขายอื่น')
        }

        return Promise.resolve()
      },
    }
  }, [periodDateRangeExists])

  return (
    <AppModal visible={visible} destroyOnClose forceRender>
      <Form form={form} onFinish={onSubmit} layout="vertical" autoComplete="off">
        <AppModal.Header onCloseClick={handleDismiss}>{modalHeaderLabel}</AppModal.Header>
        <AppModal.Body>
          <Form.Item label="รอบการขาย" name="description" rules={REQUIRED_RULES} required>
            <Input placeholder="รอบการขาย" maxLength={255} />
          </Form.Item>
          <Form.Item
            label="วันที่เริ่มจองได้ - วันที่สิ้นสุดการจอง"
            name="bookingDateRange"
            rules={REQUIRED_RULES}
            required
          >
            <DatePicker.RangePicker />
          </Form.Item>

          <Form.Item label="สถานะบริการ (Status)" name="isActive" rules={REQUIRED_RULES}>
            <Radio.Group options={statusOptions} />
          </Form.Item>
          <Form.Item
            label="วันที่เริ่มขาย - วันที่สิ้นสุดขาย"
            name="dateRange"
            dependencies={['bookingDateRange']}
            rules={[...REQUIRED_RULES, checkBookingDateRule, checkDateOverlapRule]}
            required
          >
            <DatePicker.RangePicker />
          </Form.Item>
        </AppModal.Body>
        <Row className="custom-modal-footer" wrap={false} justify="space-between" style={{ width: '100%' }}>
          <Col>
            <Button onClick={handleDismiss}>ยกเลิก</Button>
          </Col>
          <Col>
            <Space size={18} direction="horizontal">
              {preInitialValues.id && (
                <Button type="primary" onClick={onCancelKioskPeriodClick.bind(null, preInitialValues.id)} danger>
                  ยกเลิกรอบการขาย
                </Button>
              )}
              <Button type="primary" htmlType="submit">
                {submitButtonLabel}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </AppModal>
  )
}
