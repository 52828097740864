import { Button, Col, Form, Row } from 'antd'
import { styled, css } from 'goober'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { REQUIRED_RULES } from '_backend/constants/form-rules'
import { DebounceSelect } from 'components/DebounceSelect'
import { FormModal } from 'components/FormModal'
import { IPromotionCodeResponse } from 'services/enco-service/enco-service-response'
import { useCheckPromotionCodeMTT, useGetEmployeePromotionCodeQRY } from 'services/enco-service/enco-service-service'

// const ColFlex = styled(Col)`
//   display: flex;
//   flex-direction: column;
// `

// const CustomCard = styled(Card)`
//   position: relative;
//   min-width: 250px;
//   margin-bottom: 12px;
// `

// const UseCodeButton = styled(Button)`
//   position: absolute;
//   top: 24px;
//   right: 16px;
//   border-radius: 50%;
// `

interface IPromotionCodeOptions {
  key: string
  label: string
  value: string
}

// interface PromotionCodeItemProps {
//   promo: IPromotionCodeResponse
// }

// const PromotionCodeItem = (props: PromotionCodeItemProps) => {
//   const { promo } = props
//   const form = Form.useFormInstance<IPromotionCodeModalFormValues>()
//   const promotionCodes = Form.useWatch(['promotionCodes'], form)

//   const isSelected = useMemo(() => {
//     return !!promotionCodes?.find((e) => e.value === promo.promoCode)
//   }, [promo.promoCode, promotionCodes])

//   const handleSelectCode = useCallback(() => {
//     form.setFieldsValue({
//       promotionCodes: uniqBy(
//         [
//           ...promotionCodes,
//           {
//             key: promo.promoCode,
//             value: promo.promoCode,
//             label: promo.promoCode,
//           },
//         ],
//         (e) => e.value,
//       ),
//     })
//   }, [form, promo.promoCode, promotionCodes])

//   return (
//     <CustomCard>
//       <ColFlex>
//         <Txt ag="header18">{promo.promoCode}</Txt>
//         <Txt ag="subtitle14">
//           {promo.startdatedisplay} - {promo.expiredatedisplay}
//         </Txt>
//       </ColFlex>
//       <UseCodeButton
//         onClick={handleSelectCode}
//         style={{ marginRight: '12px', height: '32px', borderRadius: '16px' }}
//         disabled={isSelected}
//       >
//         เลือก
//       </UseCodeButton>
//     </CustomCard>
//   )
// }

const ContentContainer = styled('div')`
  width: 100%;
  max-width: 400px;

  .ant-select-selector .ant-select-selection-search-input {
    padding-right: unset !important;
  }
`

const CustomButton = styled(Button)`
  margin-top: 6px;
`

// const CodeItemContainer = styled('div')`
//   margin-top: 32px;
//   max-height: 400px;
//   overflow-y: auto;
// `

interface IPromotionCodeModalFormValues {
  promotionCodes: IPromotionCodeOptions[]
}

interface PromotionCodeModalProps {
  serviceId: number
  visible: boolean
  onClose: () => void
  selectedPromoCodes: IPromotionCodeResponse[]
  onSelectCode: (promotionCodes: IPromotionCodeResponse[]) => void
}

export const PromotionCodeModal = (props: PromotionCodeModalProps) => {
  const { serviceId, visible, onClose, onSelectCode, selectedPromoCodes } = props

  const { data: employeePromotions, refetch } = useGetEmployeePromotionCodeQRY({ serviceId })
  const { mutateAsync: checkPromotionCode, data: checkPromotionCodeResponse, isSuccess } = useCheckPromotionCodeMTT()
  const [form] = Form.useForm<IPromotionCodeModalFormValues>()

  const [promotionCodeHash, setPromotionCodeHash] = useState<Record<string, IPromotionCodeResponse>>({})

  const handleCloseModal = useCallback(() => {
    onClose()
  }, [onClose])

  useEffect(() => {
    if (visible) refetch()
  }, [refetch, visible])

  const fetchCheckPromotion = useCallback(
    async (text: string): Promise<IPromotionCodeOptions[]> => {
      const response = await checkPromotionCode({ promoCode: text, serviceId })

      if (response.tbPromoDtId) {
        setPromotionCodeHash((prev) => {
          prev[response.promoCode] = response
          return prev
        })
        return [
          {
            key: response.promoCode,
            label: response.promoCode,
            value: response.promoCode,
          },
        ]
      }

      return []
    },
    [checkPromotionCode, serviceId],
  )

  const onSubmit = useCallback(
    (values: IPromotionCodeModalFormValues) => {
      const promotionCodesResult = values.promotionCodes.map((e) => {
        return promotionCodeHash[e.value]
      })
      onSelectCode(promotionCodesResult)
      onClose()
    },
    [onClose, onSelectCode, promotionCodeHash],
  )

  useEffect(() => {
    if (employeePromotions?.length) {
      setPromotionCodeHash((prev) => {
        employeePromotions?.forEach((e) => {
          prev[e.promoCode] = e
        })
        return prev
      })
    }
  }, [employeePromotions])

  useEffect(() => {
    if (selectedPromoCodes?.length) {
      setPromotionCodeHash((prev) => {
        selectedPromoCodes?.forEach((e) => {
          prev[e.promoCode] = e
        })
        return prev
      })
    }
  }, [selectedPromoCodes])

  const initialValues = useMemo((): IPromotionCodeModalFormValues => {
    return {
      promotionCodes: (selectedPromoCodes ?? []).map((e) => {
        return {
          key: e.promoCode,
          label: e.promoCode,
          value: e.promoCode,
        }
      }),
    }
  }, [selectedPromoCodes])

  return (
    <FormModal
      title="โค้ดส่วนลด"
      visible={visible}
      onCancel={handleCloseModal}
      width={800}
      className={css`
        .form-modal-body {
          background: unset;
        }
      `}
    >
      <ContentContainer>
        <Form form={form} layout="vertical" initialValues={initialValues} onFinish={onSubmit}>
          <Row gutter={12} align="middle" wrap={false}>
            <Col flex={1}>
              <Form.Item
                label="โค้ดส่วนลด"
                name="promotionCodes"
                rules={REQUIRED_RULES}
                validateStatus={isSuccess && !checkPromotionCodeResponse?.tbPromoDtId ? 'error' : undefined}
                help={isSuccess && !checkPromotionCodeResponse?.tbPromoDtId ? 'โค้ดไม่ถูกต้อง' : undefined}
              >
                <DebounceSelect
                  mode="multiple"
                  placeholder="กรอกโปรโมชั่นโค้ดที่นี่"
                  fetchOptions={fetchCheckPromotion}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col flex="100px">
              <CustomButton style={{ paddingBottom: '6px', paddingTop: '6px' }} type="primary" htmlType="submit">
                ใช้โค้ด
              </CustomButton>
            </Col>
          </Row>
        </Form>

        {/* <Txt ag="header28" color="grayPrimaryText">
          โค้ดส่วนลดที่สามารถใช้ได้
        </Txt>
        <CodeItemContainer>
          {employeePromotions?.map((promo) => (
            <PromotionCodeItem key={promo.tbPromoDtId} promo={promo} />
          ))}
        </CodeItemContainer> */}
      </ContentContainer>
    </FormModal>
  )
}
