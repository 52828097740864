/* eslint-disable unused-imports/no-unused-imports */
import { useMutation, useQuery } from '@tanstack/react-query'

import { FileHelper } from 'helpers/file-helper'
import { ContentType } from 'services/http-client/base-http-client'
import { encoClient } from 'services/http-client/enco-client'
import { ResponseType } from 'services/response-type'

import { IGetReportParams } from './report-params'
import {
  IExportReportResponse,
  IGetMasterStadiumPackageResponse,
  IGetReportListResponse,
  IGetReportResponse,
  INewReportDataResponse,
} from './report-response'

const GET_REPORT_LIST_PATH = '/ReportList'
const GET_REPORT_PATH = '/Report'
const REPORT_STADIUM_PACKAGES_PATH = '/ReportStadiumPackages'
const EXPORT_REPORT_PATH = '/ExportReport'
const GET_MASTER_STADIUM_PACKAGE_PATH = '/MasterStadiumPackage'
// [GET]GetMasterStadiumPackage.packageName

export const useGetReportListQRY = () => {
  return useQuery([GET_REPORT_LIST_PATH], async () => {
    const res = await encoClient.get<ResponseType<IGetReportListResponse[]>>(GET_REPORT_LIST_PATH, {
      headers: { 'Content-Type': ContentType.FORM_DATA },
    })
    return res.data.data
  })
}

export const useGetReportQRY = (params: IGetReportParams) => {
  return useQuery([GET_REPORT_PATH, params], async () => {
    try {
      const res = await encoClient.post<ResponseType<IGetReportResponse>>(GET_REPORT_PATH, params, {
        headers: { 'Content-Type': ContentType.FORM_DATA },
      })
      return res.data.data
    } catch (e) {
      return null
    }
  })
}

export const useGetReportNewQRY = (params: IGetReportParams) => {
  return useQuery([REPORT_STADIUM_PACKAGES_PATH, params], async () => {
    try {
      const res = await encoClient.post<ResponseType<IGetReportResponse>>(REPORT_STADIUM_PACKAGES_PATH, params, {
        headers: { 'Content-Type': ContentType.FORM_DATA },
      })
      const data = res.data.data
      const reportData = JSON.parse(data.reportData) as INewReportDataResponse
      return {
        ...data,
        reportData,
      }
    } catch (e) {
      return null
    }
  })
}

export const useExportReportMTT = () => {
  return useMutation(async (params: IGetReportParams) => {
    const res = await encoClient.post<ResponseType<IExportReportResponse>>(EXPORT_REPORT_PATH, params)
    const data = res.data.data
    const base64Response = await fetch(
      `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data.fileString}`,
    )
    const blob = await base64Response.blob()
    const blobUrl = URL.createObjectURL(blob)
    FileHelper.download(blobUrl, data.fileName)
  })
}

export const useGetMasterStadiumPackagesQRY = () => {
  return useQuery([GET_MASTER_STADIUM_PACKAGE_PATH], async () => {
    const res = await encoClient.get<ResponseType<IGetMasterStadiumPackageResponse[]>>(
      GET_MASTER_STADIUM_PACKAGE_PATH,
      {
        headers: { 'Content-Type': ContentType.FORM_DATA },
      },
    )
    return res.data.data
  })
}
