import { Button, Card, Col, Divider, Form, Input, notification, Row, Select } from 'antd'
import { ValidateStatus } from 'antd/lib/form/FormItem'
import dayjs from 'dayjs'
import { styled } from 'goober'
import { chain } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { apiDateFormat } from '_backend/constants/date-format'
import DatePicker from 'components/DatePicker'
import { Txt } from 'components/Txt'
import { backendPaths } from 'paths/backend-path-config'
import {
  IGetServiceSlotSelectResponse,
  IPromotionCodeResponse,
  IServiceData,
} from 'services/enco-service/enco-service-response'
import { useGetServiceSlotSelectQRY } from 'services/enco-service/enco-service-service'
import { useGetServiceItemListQRY } from 'services/item-management/item-management-service'
import { IGetSearchServicePropResponse } from 'services/property-management/property-management-response'
import { useGetSearchServicePropQRY } from 'services/property-management/property-management-service'

import { ItemSelector, ItemWithQty } from './ItemSelector'
import { SlotSelector } from './SlotSelector'

const SectionTitleContainer = styled.div<{ centered: string }>`
  text-align: ${(props) => (props.centered.toLocaleLowerCase() === 'true' ? 'center' : 'left')};
  margin-bottom: 16px;
`

export const SectionTitle = (props: { txt: string; centered?: boolean }) => {
  const { txt, centered = false } = props
  return (
    <SectionTitleContainer centered={String(centered)}>
      <Txt ag="header30" color="grayPlaceholder">
        {txt}
      </Txt>
    </SectionTitleContainer>
  )
}

const CustomSelector = styled(Select)`
  width: 100%;
  text-align: left;
  .ant-select-selector {
    width: 100%;
    text-align: left;
    align-items: center;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`

const CustomButton = styled(Button)`
  width: 343px;
  margin: 14px;
`

export interface IBookingSummaryOrderProps {
  property?: IGetSearchServicePropResponse
  date: string // YYYY/MM/DD
  slots: IGetServiceSlotSelectResponse[]
  items: ItemWithQty[]

  selectedPromoCodes?: IPromotionCodeResponse[]
}

interface OrderDetailFormProps {
  service: IServiceData
  onClickContinue: () => void
}

export const OrderDetailForm = (props: OrderDetailFormProps) => {
  const { service, onClickContinue } = props

  const form = Form.useFormInstance<IBookingSummaryOrderProps>()

  const property = Form.useWatch(['property'], form)
  const date = Form.useWatch(['date'], form)
  const slots = Form.useWatch(['slots'], form)
  // const items = Form.useWatch(['items'], form)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const canReserveIn = useCallback(() => dayjs().add((service.minDateBookingDay || 0) - 1, 'day'), [service])

  const { data: serviceProperties } = useGetSearchServicePropQRY({ ServiceId: service.id })
  const { data: serviceSlots } = useGetServiceSlotSelectQRY({
    bookdate: date ? dayjs(date).format(apiDateFormat) : canReserveIn().format(apiDateFormat),
    servicePropId: property?.id,
  })

  const { data: itemOther } = useGetServiceItemListQRY({ ServiceId: service.id, IsActive: 1, itemTypeId: 3 })

  const propertiesMap = useMemo(() => {
    let returnItems: { [id: number]: IGetSearchServicePropResponse } = {}
    serviceProperties?.forEach((prop) => (returnItems[prop.id] = prop))
    return returnItems
  }, [serviceProperties])

  const handlePropertiesChange = useCallback(
    (value: unknown) => {
      const selected = propertiesMap[value as number]
      form.setFieldsValue({
        property: selected,
        date: undefined,
      })
    },
    [propertiesMap, form],
  )

  const disabledDate = useCallback(
    (e: dayjs.Dayjs) => {
      if (!service) return true
      return e.diff(canReserveIn()) < 0
    },
    [canReserveIn, service],
  )

  const handleClickContinue = useCallback(() => {
    setIsSubmitting(true)
    if (slots.length < 2)
      notification.error({ message: 'ผิดพลาด', description: 'กรุุณาเลือกช่วงเวลาอย่างน้อย 2 รายการ' })
    if (date && property && slots.length > 1) {
      onClickContinue()
    }
  }, [onClickContinue, date, property, slots])

  const isPropertiesError = (): { status: ValidateStatus; help?: string } => {
    if (isSubmitting) {
      if (property) return { status: '', help: undefined }
      return { status: 'error', help: `กรุุณาเลือก${service.propUnitName}` }
    } else {
      return { status: '', help: undefined }
    }
  }

  const isSelectedDateError = (): { status: ValidateStatus; help?: string } => {
    if (isSubmitting) {
      if (date) return { status: '', help: undefined }
      return { status: 'error', help: 'กรุณาเลือกวันที่' }
    } else {
      return { status: '', help: undefined }
    }
  }

  const encoServiceOrderListPath = useMemo(
    () =>
      backendPaths.serviceOrderList({
        routeParam: {
          serviceId: service.id,
        },
      }),
    [service.id],
  )

  const propertyOptions = useMemo(() => {
    return chain(serviceProperties ?? [])
      .filter((p) => p.active === 1)
      .map((e) => ({
        label: e.name,
        value: e.id,
      }))
      .value()
  }, [serviceProperties])

  return (
    <>
      <Card>
        <Row gutter={24}>
          <Col span={24} xl={{ span: 8 }} style={{ marginBottom: 16 }}>
            <SectionTitle txt={`เลือก${service.propUnitName}`} />
            <Form.Item name="property" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="propertyId" validateStatus={isPropertiesError().status} help={isPropertiesError().help}>
              <CustomSelector
                placeholder={`กรุณาเลือก${service.propUnitName}`}
                options={propertyOptions}
                onChange={handlePropertiesChange}
              />
            </Form.Item>
          </Col>
          <Col span={24} xl={{ span: 8 }} style={{ marginBottom: 16 }}>
            <SectionTitle txt="เลือกวันที่" />
            <Form.Item name="date" validateStatus={isSelectedDateError().status} help={isSelectedDateError().help}>
              <DatePicker
                placeholder="กรุณาเลือกวันที่"
                disabledDate={disabledDate}
                allowClear={false}
                disabled={!property}
              />
            </Form.Item>
          </Col>
          <Col span={24} xl={{ span: 8 }} style={{ marginBottom: 16 }}>
            <SectionTitle txt={`ตารางเวลา${service.propUnitName}`} />
            <Form.Item name="slots" noStyle>
              <SlotSelector
                service={service}
                slots={serviceSlots || []}
                disabled={date === null}
                isError={isSubmitting && slots.length < 1}
              />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Divider />
      <Card>
        <Form.Item name="items" noStyle>
          <ItemSelector selectedSlots={slots} masterItems={[...(itemOther || [])]}>
            <ButtonContainer>
              <CustomButton onClick={handleClickContinue} type="primary">
                Continue
              </CustomButton>
              <Link to={encoServiceOrderListPath}>
                <CustomButton>Back</CustomButton>
              </Link>
            </ButtonContainer>
          </ItemSelector>
        </Form.Item>
      </Card>
    </>
  )
}
