import { useQuery } from '@tanstack/react-query'

import { encoClient } from 'services/http-client/enco-client'
import { ResponseType } from 'services/response-type'

import { IMasterBookingTypeListResponse, IMasterQuotaStatusListResponse } from './master-response'

const MASTER_GET_QUOTATION_STATUS_PATH = '/GetMasterQuotaSt'
const GET_MASTER_BOOKING_TYPE_PATH = '/GetMasterBookingType'

export const useMasterGetQuotaStatusListQRY = () => {
  return useQuery<IMasterQuotaStatusListResponse[]>([MASTER_GET_QUOTATION_STATUS_PATH], async () => {
    const res = await encoClient.get<ResponseType<IMasterQuotaStatusListResponse[]>>(MASTER_GET_QUOTATION_STATUS_PATH)
    return res.data.data || []
  })
}

export const useGetMasterBookingTypeListQRY = () => {
  return useQuery<IMasterBookingTypeListResponse[]>([GET_MASTER_BOOKING_TYPE_PATH], async () => {
    const res = await encoClient.get<ResponseType<IMasterBookingTypeListResponse[]>>(GET_MASTER_BOOKING_TYPE_PATH)
    return res.data.data || []
  })
}
