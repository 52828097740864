import { Button, Card, Col, Form, notification, Radio, Row } from 'antd'
import { styled } from 'goober'
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { UserTypeEnum } from '_backend/constants/user-management-constants'
import { useModalConfirm } from 'components/ModalConfirmProvider'
import { useVisible } from 'helpers/modal-helper'
import { backendPaths } from 'paths/backend-path-config'
import { IUserSaveParams } from 'services/user/user-param'
import { IGetUserResponse } from 'services/user/user-response'
import {
  useGetUserDetailQRY,
  useSaveUserDetailMTT,
  useSaveUserPassMTT,
  useSaveUserPinMTT,
} from 'services/user/user-service'
import theme from 'theme/goober'

import { IUserDetailFormValue, UserDetailForm } from './UserDetailForm'
import { IUserResetPasswordFormValues, UserResetPasswordModal } from './UserResetPasswordModal'

const ButtonGroupRow = styled(Row)`
  margin-bottom: 32px;
`

const ButtonOrange = styled(Button)`
  /* border-color: ${theme.color.orangePrimary};
  color: ${theme.color.orangePrimary}; */
`

const convertToFormValues = (user: IGetUserResponse): IUserDetailFormValue => ({
  prefix: user.prefix,
  accountType: user.accountType,
  active: user.active === 1,
  isPttRelate: user.isPttRelate ?? 0,
  firstName: user.firstname,
  lastName: user.lastname,
  email: user.email,
  logon: user.logon,
  profile: user.profile,
  tel: user.tel,
  userGroupId: user.userGrpId,
})

export const PageUserEdit = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const userId = query.get('userId') ?? ''

  const [form] = Form.useForm()

  const resetPasswordVisible = useVisible()
  const modalConfirm = useModalConfirm()

  const { data: userDetail } = useGetUserDetailQRY(userId, !!userId)

  const { mutateAsync: saveUser } = useSaveUserDetailMTT()
  const { mutateAsync: saveUserPass } = useSaveUserPassMTT()
  const { mutateAsync: saveUserPin } = useSaveUserPinMTT()

  const [userType, setUserType] = useState<UserTypeEnum>(UserTypeEnum.enco)

  const onUserTypeChange = useCallback(
    (type: UserTypeEnum) => {
      setUserType(type)
    },
    [setUserType],
  )

  const handleSaveSuccess = useCallback(() => {
    navigate(backendPaths.userList())
    notification.success({ message: 'สำเร็จ', description: 'บันทึกข้อมูลผู้ใช้งานสำเร็จ', duration: 2 })
  }, [navigate])

  const handleSubmitForm = useCallback(
    async (values: IUserDetailFormValue) => {
      if (userDetail) {
        const userForSave: IUserSaveParams = {
          Userid: userDetail.userid,
          Prefix: values.prefix,
          Firstname: values.firstName,
          Lastname: values.lastName,
          Email: values.email,
          Empcode: userDetail.empcode,
          Logon: values.logon,
          Tel: values.tel,
          UserTypeId: userDetail.userTypeId,
          MdAccountTypeId: userDetail.mdAccountTypeId,
          Profile: values.profile,
          UserGrpId: values.userGroupId,
          Active: values.active ? 1 : 0,
          Password: userDetail.password,
          pinCode: userDetail.pinCode,
          isPttRelate: values.isPttRelate ?? 0,
        }
        if (userDetail.company) userForSave.Company = userDetail.company
        await saveUser(userForSave, { onSuccess: handleSaveSuccess })
      }
    },
    [saveUser, userDetail, handleSaveSuccess],
  )

  useEffect(() => {
    if (userDetail?.userTypeId) setUserType(userDetail?.userTypeId)
  }, [userDetail?.userTypeId])

  const onClickResetPassword = useCallback(() => {
    resetPasswordVisible.open()
  }, [resetPasswordVisible])

  const onClickConfirmResetPassword = useCallback(
    async (values: IUserResetPasswordFormValues) => {
      resetPasswordVisible.close()
      if (userDetail) {
        await saveUserPass(
          {
            userID: userDetail.userid,
            Password: values.password,
          },
          { onSuccess: handleSaveSuccess },
        )
      }
    },
    [handleSaveSuccess, resetPasswordVisible, saveUserPass, userDetail],
  )

  const onClickDeletePinCode = useCallback(() => {
    modalConfirm.show({
      type: 'default',
      title: 'คุณยืนยันที่จะลบ PIN Code หรือไม่ ?',
      content: 'หากยืนยันผู้ใช้จะต้องดำเนินการตั้งค่า PIN Code ใหม่',
      modalProps: {
        width: 700,
      },
      onOk: async () => {
        if (userDetail) {
          const userForSave: IUserSaveParams = {
            Userid: userDetail.userid,
            Prefix: userDetail.prefix,
            Firstname: userDetail.firstname,
            Lastname: userDetail.lastname,
            Email: userDetail.email,
            Empcode: userDetail.empcode,
            Logon: userDetail.logon,
            Tel: userDetail.tel,
            UserTypeId: userDetail.userTypeId,
            MdAccountTypeId: userDetail.mdAccountTypeId,
            Profile: userDetail.profile,
            UserGrpId: userDetail.userGrpId,
            Active: userDetail.active ? 1 : 0,
            Password: userDetail.password,
            isPttRelate: userDetail.isPttRelate ?? 0,
            pinCode: '', // for reset pin
          }
          if (userDetail.company) userForSave.Company = userDetail.company
          await saveUserPin(
            {
              userID: userDetail.userid,
              PinCode: '', // emprty string for reset pin code
            },
            { onSuccess: handleSaveSuccess },
          )
        }
      },
    })
  }, [handleSaveSuccess, modalConfirm, saveUserPin, userDetail])

  return (
    <>
      <ContentHeader subTitle="แก้ไขผู้ใช้งาน" />
      <ContentBody max-width="686px">
        <Card>
          {userDetail && (
            <Form
              layout="vertical"
              form={form}
              initialValues={convertToFormValues(userDetail)}
              onFinish={handleSubmitForm}
            >
              <ButtonGroupRow>
                <Col sm={{ span: 12 }} span={24}>
                  <Radio.Group value={userType} buttonStyle="solid" onChange={(e) => onUserTypeChange(e.target.value)}>
                    {userType === UserTypeEnum.enco && (
                      <Radio.Button value={UserTypeEnum.enco}>พนักงาน EnCo</Radio.Button>
                    )}
                    {userType === UserTypeEnum.bsa && <Radio.Button value={UserTypeEnum.bsa}>พนักงาน BSA</Radio.Button>}
                  </Radio.Group>
                </Col>
              </ButtonGroupRow>

              <UserDetailForm
                userType={userType}
                extraComponent={
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Row>
                        <Col xs={24} md={12} style={{ paddingRight: '16px' }}>
                          <ButtonOrange onClick={onClickResetPassword}>Reset Password</ButtonOrange>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row>
                        <Col xs={24} md={12} style={{ paddingRight: '16px' }}>
                          <Button onClick={onClickDeletePinCode} danger ghost>
                            Delete PIN Code
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                }
              />
            </Form>
          )}
        </Card>
      </ContentBody>
      <UserResetPasswordModal
        visible={resetPasswordVisible.visible}
        onClickConfirm={onClickConfirmResetPassword}
        onCloseModal={resetPasswordVisible.close}
      />
    </>
  )
}
