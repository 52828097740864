import { PlusOutlined, FilePdfOutlined } from '@ant-design/icons'
import { Button, Col, DatePicker, Empty, Form, Image, notification, Radio, Row, Select, Space, Upload } from 'antd'
import { CheckboxOptionType } from 'antd/es/checkbox'
import { DefaultOptionType } from 'antd/lib/select'
import dayjs, { Dayjs } from 'dayjs'
import { styled } from 'goober'
import { chain, map, omit } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { apiDateFormat } from '_backend/constants/date-format'
import { REQUIRED_RULES } from '_backend/constants/form-rules'
import { Input, TextArea } from 'components/Input'
import { useModalConfirm } from 'components/ModalConfirmProvider'
import { Txt } from 'components/Txt'
import { FileHelper } from 'helpers/file-helper'
import { useVisible } from 'helpers/modal-helper'
import { backendPaths } from 'paths/backend-path-config'
import { useGetServiceQRY } from 'services/enco-service/enco-service-service'
import {
  useGetKioskMasterDistrictQRY,
  useGetKioskMasterProductListQRY,
  useGetKioskMasterProvinceQRY,
  useGetKioskMasterTambonQRY,
} from 'services/kiosk-master/kiosk-master-service'
import { IApproveKioskRegisterParams, ISubmitKioskRegisterParams } from 'services/kiosk-register/kiosk-register-params'
import {
  EnumKioskRegisterApproveId,
  EnumKioskRegisterApproveStatus,
  EnumKioskRegisterBanFlag,
  EnumKioskRegisterIsPermanentBan,
} from 'services/kiosk-register/kiosk-register-response'
import {
  useApproveKioskRegisterMTT,
  useGetKioskRegisterQRY,
  useSaveKioskRegisterMTT,
} from 'services/kiosk-register/kiosk-register-service'
import theme from 'theme/goober'

import {
  ModalConfirmKioskMemberRegisterApprove,
  ModalConfirmKioskMemberRegisterApproveFormValues,
} from './ModalConfirmKioskMemberRegisterApprove'

const FileContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 112px;
  max-width: max-content;
`

interface IKioskMemberManagementFormValues extends ISubmitKioskRegisterParams {
  status: EnumKioskRegisterApproveStatus | null
  banDateRange: [Dayjs | undefined, Dayjs | undefined]
  vendorPath: string
}

export const PageKioskMemberManagementDetail = () => {
  const { serviceId = 0, registerId = 0 } = useParams()

  const [form] = Form.useForm<IKioskMemberManagementFormValues>()

  const modalConfirm = useModalConfirm()
  const navigate = useNavigate()
  const formStatus = Form.useWatch('status', form)

  const { data: service } = useGetServiceQRY(Number(serviceId))
  const { data: preInitialValues } = useGetKioskRegisterQRY({
    id: Number(registerId),
  })

  const { mutate: saveKioskRegister } = useSaveKioskRegisterMTT()
  const { mutate: approveKioskRegister } = useApproveKioskRegisterMTT()
  const modalKioskRegisterApproveVisible = useVisible<ModalConfirmKioskMemberRegisterApproveFormValues>()

  const canEdit = useMemo(() => formStatus === EnumKioskRegisterApproveStatus.APPROVED, [formStatus])

  const initialValues = useMemo((): Partial<IKioskMemberManagementFormValues> => {
    const { id, vendorFileName, vendorPath, banFlg, banStartDate, banEndDate, status, ...restPreInitialValues } =
      preInitialValues ?? {}
    return {
      id: id ?? 0,
      VendorFileName: vendorFileName ?? '',
      VendorPath: vendorPath ?? '',
      vendorPath: vendorPath ?? '',
      status,
      banFlg: banFlg ?? EnumKioskRegisterBanFlag.AVAILABLE,
      banDateRange: [banStartDate ? dayjs(banStartDate) : undefined, banEndDate ? dayjs(banEndDate) : undefined],
      ...restPreInitialValues,
    }
  }, [preInitialValues])

  const onBackClick = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const onModalApproveConfirm = useCallback(
    (data: IApproveKioskRegisterParams) => {
      if (serviceId) {
        approveKioskRegister(data, {
          onSuccess() {
            notification.success({ message: 'สำเร็จ', description: 'บันทึกสำเร็จ', duration: 2 })
            navigate(
              backendPaths.serviceKioskMemberManagementList({
                routeParam: {
                  serviceId: +(serviceId || 0),
                },
              }),
              {
                replace: true,
              },
            )
          },
          onSettled: () => {},
        })
      }
    },
    [approveKioskRegister, navigate, serviceId],
  )

  const getFormValues = useCallback(async () => {
    return form
      .validateFields()
      .then((formValues): ISubmitKioskRegisterParams => {
        const { banDateRange, ...restData } = formValues
        const [banStartDate, banEndDate] = banDateRange ?? []
        return {
          ...restData,
          banStartDate: banStartDate ? dayjs(banStartDate).format(apiDateFormat) : null,
          banEndDate: banEndDate ? dayjs(banEndDate).format(apiDateFormat) : null,
        }
      })
      .catch((error) => {
        form.submit()
        return Promise.reject(error)
      })
  }, [form])

  const handleApproveClick = useCallback(async () => {
    const formValues = await getFormValues()
    modalKioskRegisterApproveVisible.open({
      Id: formValues.id,
      IsApprove: EnumKioskRegisterApproveId.APPROVED,
      ApproveNote: '',
    })
  }, [getFormValues, modalKioskRegisterApproveVisible])

  const handleRejectClick = useCallback(async () => {
    const formValues = await getFormValues()
    modalKioskRegisterApproveVisible.open({
      Id: formValues.id,
      IsApprove: EnumKioskRegisterApproveId.REJECT,
      ApproveNote: '',
    })
  }, [getFormValues, modalKioskRegisterApproveVisible])

  const handleCancelBanClick = useCallback(async () => {
    const formValues = await getFormValues()

    modalConfirm.show({
      type: 'danger',
      title: 'ยืนยันยกเลิกแบน',
      content: 'ยืนยันยกเลิกแบน ใช่หรือไม่ ?',
      onOk: () => {
        saveKioskRegister(
          omit(
            {
              ...formValues,
              banFlg: EnumKioskRegisterBanFlag.CANCEL_BAN,
              banRemark: '',
              banEndDate: null,
              banStartDate: null,
              isPermanentBan: null,
            },
            'status',
          ),
          {
            onSuccess() {
              notification.success({ message: 'สำเร็จ', description: 'บันทึกการยกเลิกแบนสำเร็จ', duration: 2 })
              navigate(-1)
            },
          },
        )
      },
    })
  }, [getFormValues, modalConfirm, navigate, saveKioskRegister])

  const handleSaveClick = useCallback(async () => {
    const formValues = await getFormValues()
    if (formValues.banFlg === EnumKioskRegisterBanFlag.BANNED) {
      modalConfirm.show({
        type: 'danger',
        title: 'ยืนยันแบน',
        content: 'ยืนยันแบน ใช่หรือไม่ ?',
        onOk: () => {
          saveKioskRegister(omit(formValues, 'status'), {
            onSuccess() {
              notification.success({ message: 'สำเร็จ', description: 'บันทึกการแบนสำเร็จ', duration: 2 })
              navigate(-1)
            },
          })
        },
      })
    } else {
      modalConfirm.show({
        type: 'default',
        title: 'ยืนยันบันทึกข้อมูล',
        content: 'ยืนยันบันทึกข้อมูล ใช่หรือไม่ ?',
        onOk: () => {
          saveKioskRegister(omit(formValues, 'status'), {
            onSuccess() {
              notification.success({ message: 'สำเร็จ', description: 'บันทึกสำเร็จ', duration: 2 })
              navigate(-1)
            },
          })
        },
      })
    }
  }, [getFormValues, modalConfirm, navigate, saveKioskRegister])

  const { data: kioskProducts } = useGetKioskMasterProductListQRY(Number(preInitialValues?.tbServiceId))

  const { data: provinces } = useGetKioskMasterProvinceQRY()
  const { data: districts } = useGetKioskMasterDistrictQRY({
    ProvinceID: preInitialValues?.provinceID,
  })
  const { data: tambons } = useGetKioskMasterTambonQRY({
    ProvinceID: preInitialValues?.provinceID,
    DistrictID: preInitialValues?.districtID,
  })

  const tambonOptions = useMemo((): DefaultOptionType[] => {
    return map(tambons ?? [], (e) => ({
      label: e.tambonThai,
      value: e.tambonId,
    }))
  }, [tambons])

  const districtOptions = useMemo((): DefaultOptionType[] => {
    return map(districts ?? [], (e) => ({
      label: e.districtThai,
      value: e.districtId,
    }))
  }, [districts])

  const provinceOptions = useMemo((): DefaultOptionType[] => {
    return map(provinces ?? [], (e) => ({
      label: e.provinceThai,
      value: e.provinceId,
    }))
  }, [provinces])

  const productOptions = useMemo((): DefaultOptionType[] => {
    return chain(kioskProducts ?? [])
      .transform((acc: DefaultOptionType[], e) => {
        if (e.isActive || initialValues?.mdKioskProductId === e.id) {
          acc.push({
            label: e.productName,
            value: e.id,
            disabled: !e.isActive,
          })
        }
        return acc
      }, [])
      .uniqBy('value')
      .value()
  }, [initialValues?.mdKioskProductId, kioskProducts])

  const subProductOptions = useMemo((): DefaultOptionType[] => {
    return chain(kioskProducts ?? [])
      .flatMap((e) => e.mdKioskSubProductDtos ?? [])
      .transform((acc: DefaultOptionType[], e) => {
        if (e.isActive || initialValues?.mdKioskSubProductId === e.id) {
          acc.push({
            label: e.subProductName,
            value: e.id,
            disabled: !e.isActive,
          })
        }
        return acc
      }, [])
      .uniqBy('value')
      .value()
  }, [initialValues?.mdKioskSubProductId, kioskProducts])

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  const actionButtons = useMemo(() => {
    if (preInitialValues?.status === EnumKioskRegisterApproveStatus.WAITING) {
      return (
        <>
          <Button type="primary" onClick={handleApproveClick}>
            Approve
          </Button>
          <Button type="primary" onClick={handleRejectClick} danger>
            Reject
          </Button>
        </>
      )
    }

    const canCancelBan = initialValues.status === EnumKioskRegisterApproveStatus.BANNED
    return (
      <>
        {canCancelBan && (
          <Button type="primary" onClick={handleCancelBanClick} danger>
            ยกเลิกโทษแบน
          </Button>
        )}
        <Button type="primary" onClick={handleSaveClick}>
          บันทึก
        </Button>
      </>
    )
  }, [
    handleApproveClick,
    handleCancelBanClick,
    handleRejectClick,
    handleSaveClick,
    initialValues.status,
    preInitialValues?.status,
  ])

  return (
    <>
      <ContentHeader title={service?.name} subTitle="Member Management" />
      <Form form={form} onFinish={handleApproveClick} layout="vertical" autoComplete="off">
        <KeepValues />

        <Row gutter={18}>
          <Col xs={24} sm={12}>
            <Form.Item label="ชื่อ" name="firstname">
              <Input placeholder="-" disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="นามสกุล" name="lastname">
              <Input placeholder="-" disabled />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item label="เลขบัตรประชาชน" name="cid">
              <Input placeholder="-" disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="ตำบล" name="tambonID">
              <Select placeholder="-" options={tambonOptions} disabled />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item label="อำเภอ" name="districtID">
              <Select placeholder="-" options={districtOptions} disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="จังหวัด" name="provinceID">
              <Select placeholder="-" options={provinceOptions} disabled />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item label="ที่อยู่ปัจจุบัน" name="address">
              <Input placeholder="-" disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="เบอร์โทรติดต่อ" name="tel">
              <Input placeholder="-" disabled />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item label="Email" name="email">
              <Input placeholder="-" disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} />

          <Col xs={24} sm={12}>
            <Form.Item label="ประเภทสินค้าที่ต้องการขาย" name="mdKioskProductId" rules={REQUIRED_RULES} required>
              <Select placeholder="-" options={productOptions} disabled={!canEdit} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="ประเภทสินค้าย่อย" name="mdKioskSubProductId" rules={REQUIRED_RULES} required>
              <Select placeholder="-" options={subProductOptions} disabled={!canEdit} />
            </Form.Item>
          </Col>

          <MemberRegisterImageSection />

          <Col xs={24} sm={12}>
            <Form.Item label="เจ้าหน้าที่ผู้อนุมัติ" name="approvStf">
              <Input placeholder="-" disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="วันที่อนุมัติ" name="approveDate">
              <Input placeholder="-" disabled />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item label="เหตุผล กรณีไม่อนุมัติ" name="approveNote">
              <Input placeholder="-" disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="หมายเหตุ" name="adminNote">
              <TextArea autoSize={{ minRows: 5, maxRows: 5 }} />
            </Form.Item>
          </Col>

          <MemberRegisterBanSection />
        </Row>
        <Row wrap={false} justify="space-between" style={{ width: '100%', marginTop: '20px' }}>
          <Col>
            <Button onClick={onBackClick}>กลับ</Button>
          </Col>
          <Col>
            <Space size={18} direction="horizontal">
              {actionButtons}
            </Space>
          </Col>
        </Row>
      </Form>
      <ModalConfirmKioskMemberRegisterApprove
        visible={modalKioskRegisterApproveVisible.visible}
        onDismiss={modalKioskRegisterApproveVisible.close}
        initialValues={modalKioskRegisterApproveVisible.data}
        onConfirm={onModalApproveConfirm}
      />
    </>
  )
}

const MemberRegisterImageSection = () => {
  return (
    <Col span={24} style={{ marginTop: '40px' }}>
      <Form.Item>
        <Row gutter={[18, 8]}>
          <Col xs={24} sm={12}>
            <Txt ag="header24">เอกสารสำเนาบัตรประชาชน พร้อมรับรองสำเนาถูกต้อง จำนวน 1 ฉบับ </Txt>
          </Col>
          <Col xs={24} sm={12}>
            <Txt ag="header24">ภาพถ่ายผู้ค้า</Txt>
          </Col>
          <Col xs={24} sm={12}>
            <FilePreview name="cardPath" title="รูปถ่ายเอกสารสำเนาบัตรประชาชน พร้อมรับรองสำเนาถูกต้อง จำนวน 1 รูป" />
          </Col>
          <Col xs={24} sm={12}>
            <FilePreview name="vendorPath" title="รูปถ่ายผู้ค้าจำนวน 1 รูป" />
          </Col>

          <Col xs={24} sm={12} />
          <Col xs={24} sm={12}>
            <Txt ag="header24">ภาพถ่ายตัวอย่างสินค้าที่ต้องการขาย</Txt>
          </Col>
          <Col xs={24} sm={12} />
          <Col xs={24} sm={12}>
            <FilePreview name="productPath" title="รูปถ่ายสินค้าจำนวน 1 รูป" />
          </Col>
        </Row>
      </Form.Item>
    </Col>
  )
}

const isPermanentBanOptions: CheckboxOptionType[] = [
  {
    label: 'แบนชั่วคราว',
    value: EnumKioskRegisterIsPermanentBan.TEMPORARY,
  },
  {
    label: 'แบนถาวร',
    value: EnumKioskRegisterIsPermanentBan.PERMANENT,
  },
]

const MemberRegisterBanSection = () => {
  const form = Form.useFormInstance<IKioskMemberManagementFormValues>()
  const status = Form.useWatch('status', form)
  const banFlg = Form.useWatch('banFlg', form)
  const isPermanentBan = Form.useWatch('isPermanentBan', form)

  const showBanFields = useMemo(() => {
    return banFlg === EnumKioskRegisterBanFlag.BANNED || status === EnumKioskRegisterApproveStatus.BANNED
  }, [banFlg, status])

  const onBanClick = useCallback(() => {
    const currentStateBanned = banFlg === EnumKioskRegisterBanFlag.BANNED

    if (currentStateBanned) {
      form.setFieldsValue({
        isPermanentBan: null,
        banFlg: EnumKioskRegisterBanFlag.AVAILABLE,
        banDateRange: [undefined, undefined],
        banRemark: '',
      })
    } else {
      form.setFieldsValue({
        isPermanentBan: EnumKioskRegisterIsPermanentBan.TEMPORARY,
        banFlg: EnumKioskRegisterBanFlag.BANNED,
      })
    }
  }, [banFlg, form])

  if (status === null || status === undefined || status === EnumKioskRegisterApproveStatus.WAITING) {
    return <></>
  }

  const showBanSection =
    status === EnumKioskRegisterApproveStatus.APPROVED || status === EnumKioskRegisterApproveStatus.BANNED

  const canBan = status === EnumKioskRegisterApproveStatus.APPROVED

  return showBanSection ? (
    <Col span={24} style={{ border: `1px solid ${theme.color.grayBorder}`, padding: '20px', marginTop: '20px' }}>
      <Row gutter={[18, 20]} align="middle">
        {canBan && (
          <Col span={24}>
            <Button type="primary" onClick={onBanClick} danger>
              Ban Account
            </Button>
          </Col>
        )}
        {showBanFields && (
          <Col span={24}>
            <Row gutter={18} align="middle">
              <Col>
                <Form.Item label="แบนสมาชิก Kiosk แบบถาวร ?" name="isPermanentBan">
                  <Radio.Group options={isPermanentBanOptions} disabled={!canBan} />
                </Form.Item>
              </Col>
              {isPermanentBan === EnumKioskRegisterIsPermanentBan.PERMANENT && (
                <Col xs={24} sm={8}>
                  <Form.Item label=" " name="banRemark" rules={REQUIRED_RULES} requiredMark={false} required={false}>
                    <Input placeholder="ระบุเหตุผลการแบนถาวร" disabled={!canBan} />
                  </Form.Item>
                </Col>
              )}
              {isPermanentBan === EnumKioskRegisterIsPermanentBan.TEMPORARY && (
                <Col span={24}>
                  <Row gutter={[18, 18]}>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        label="วันที่เริ่ม (การลงโทษปิดกั้นการจอง)"
                        name="banDateRange"
                        rules={[
                          ...REQUIRED_RULES,
                          () => {
                            return {
                              validator: (_, banDateRange) => {
                                const [banStartDate, banEndDate] = banDateRange ?? []

                                if (!banStartDate || !banEndDate) {
                                  return Promise.reject(new Error('ต้องไม่เว้นว่างไว้'))
                                }

                                return Promise.resolve()
                              },
                            }
                          },
                        ]}
                        required
                      >
                        <DatePicker.RangePicker disabled={!canBan} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item label="สาเหตุการลงโทษปิดกั้นการจอง" name="banRemark" rules={REQUIRED_RULES}>
                        <Input placeholder="ระบุเหตุผลการแบนชั่วคราว" disabled={!canBan} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
        )}
      </Row>
    </Col>
  ) : (
    <></>
  )
}

const KeepValues = () => {
  return (
    <>
      {/* keep values */}
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="tbServiceId" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="firstname" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="lastname" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="cid" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="address" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="addrDisplay" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="tel" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="email" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="mdKioskProductId" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="mdKioskSubProductId" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="isPttRelate" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="pttRelateNote" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="cardFileName" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="cardPath" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="productFileName" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="productPath" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="VendorFileName" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="VendorPath" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="vendorPath" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="MdKioskApproveStatusId" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="status" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="approvStf" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="approveDate" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="approveNote" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="adminNote" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="tambonID" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="districtID" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="provinceID" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="isPermanentBan" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="banStartDate" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="banEndDate" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="banRemark" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="banFlg" hidden>
        <Input />
      </Form.Item>
      {/* keep values */}
    </>
  )
}

interface IFilePreviewProps {
  title: string
  name: string
}

const FilePreview = (props: IFilePreviewProps) => {
  const { title, name } = props

  const value = Form.useWatch(name)

  const isPdf = value?.toLowerCase()?.endsWith('.pdf')
  const [isImageError, setIsImageError] = useState(false)

  const onFileClick = useCallback(() => {
    if (value) {
      FileHelper.open(value)
    }
  }, [value])

  const onImageError = () => {
    setIsImageError(true)
  }

  const renderFile = useMemo(() => {
    if (!value) return <></>

    if (isPdf)
      return (
        <FilePdfOutlined
          style={{
            cursor: 'pointer',
            fontSize: '60px',
          }}
          onClick={onFileClick}
        />
      )

    if (isImageError) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Image Error" />

    return <Image src={value} height={112} onError={onImageError} preview />
  }, [isImageError, isPdf, onFileClick, value])

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <Txt ag="body16">{title}</Txt>
      </Col>
      {value ? (
        <Col span={24}>
          <FileContainer>{renderFile}</FileContainer>
        </Col>
      ) : (
        <Upload<File> listType="picture-card" showUploadList={false} maxCount={1} disabled>
          <button style={{ border: 0, background: 'none', cursor: 'inherit' }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </button>
        </Upload>
      )}
      <Txt ag="body16" color="gray">
        รองรับไฟล์ .pdf .jpg .png ขนาดไฟล์ไม่เกิน 5 MB
      </Txt>
    </Row>
  )
}
