import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Divider, Form, Input, Row, Select } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import dayjs from 'dayjs'
import { styled } from 'goober'
import { chain, compact, map, orderBy } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { NumberFormatValues, NumericFormat } from 'react-number-format'

import { apiDateFormat } from '_backend/constants/date-format'
import { REQUIRED_RULES } from '_backend/constants/form-rules'
import { TextArea } from 'components/Input'
import { Txt } from 'components/Txt'
import { numberFormat } from 'helpers/number-helper'
import { backendPaths } from 'paths/backend-path-config'
import { useGetUserInfoQRY } from 'services/auth/auth-service'
import { IOrderItemParams } from 'services/enco-service/enco-service-param'
import {
  IGetServiceSlotSelectResponse,
  IPromotionCodeResponse,
  IServiceData,
} from 'services/enco-service/enco-service-response'
import { useSaveOrderBookingFormAdminMTT } from 'services/enco-service/enco-service-service'
import { useGetMasterBookingTypeListQRY } from 'services/master/master-service'
import theme from 'theme/goober'

import { ItemQty, ItemWithQty } from './ItemSelector'
import { IBookingSummaryOrderProps } from './OrderDetailForm'
import { PromotionCodeModal } from './PromotionCodeModal'

const calculateItemTotalPrice = (items: IOrderItemParams[]) => {
  let total = 0
  items?.forEach((item) => (total += item.unitPrice * item.qty))
  return total
}

const PromoCodeItemContainer = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  background-color: #ebf5fc;
  padding: 4px 40px 4px 24px;
`

const CloseButton = styled(FontAwesomeIcon)`
  position: absolute;
  right: 12px;
  top: 10px;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`
interface PromoCodeItemProps {
  promoCode: string
  onRemove: () => void
}

const PromoCodeItem = (props: PromoCodeItemProps) => {
  const { onRemove, promoCode } = props
  const handleRemove = useCallback(() => {
    onRemove()
  }, [onRemove])

  return (
    <PromoCodeItemContainer>
      <CloseButton size="sm" onClick={handleRemove} icon={faClose} />
      <Txt ag="body24" style={{ color: '#737791' }}>
        {promoCode}
      </Txt>
    </PromoCodeItemContainer>
  )
}

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ItemsContainer = styled('div')`
  min-width: 300px;
  max-width: 600px;
  width: 100%;
`

const RowContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  width: 100%;
  max-width: 600px;
  word-wrap: break-word;
  border-bottom: 1px solid ${theme.color.grayBackground};
`

const TotalItemContainer = styled('div')`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid ${theme.color.grayBackground};
  padding: 12px 0;
  .summary-title {
    text-align: left;
    min-width: 100px;
    margin-right: 16px;
  }
  .summary-value {
    text-align: right;
  }
`

const ButtonContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  width: 100%;
`

const CustomButton = styled(Button)`
  width: 100%;
  max-width: 343px;
  margin: 14px;
`

const LinkTextButton = styled(Txt)`
  margin-left: 12px;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`

interface OrderSummaryProps {
  service: IServiceData
  onBack: () => void
  dataType?: 'stadium' | 'ozone'
}

const covertItemWithQtyToOrder = (item: ItemWithQty): IOrderItemParams => {
  return {
    tbItemId: item.item.id,
    qty: item.quantity,
    unitPrice: item.pricePerUnit,
    discount: item.item.discount * item.quantity,
  }
}

const convertSlotToOrder = (slot: IGetServiceSlotSelectResponse): IOrderItemParams => {
  return {
    tbItemId: slot.tbItemId,
    qty: 1,
    unitPrice: slot.price,
    mdSlotId: slot.mdSlotId,
    discount: slot.discountAmt,
  }
}

const orderSlotDesc = (slots: IGetServiceSlotSelectResponse[]) => {
  return orderBy(slots ?? [], ['price'], ['desc'])
}

const calculateDiscountPercent = (percent: number, price: number, amount: number) => {
  const totalPrice = price * amount
  const discount = totalPrice * (percent / 100)
  return discount
}

export const OrderSummary = (props: OrderSummaryProps) => {
  const { service, onBack, dataType } = props

  const form = Form.useFormInstance<IBookingSummaryOrderProps>()

  const property = Form.useWatch(['property'], form)
  const date = Form.useWatch(['date'], form)
  const slots = Form.useWatch(['slots'], form)
  const rawItems = Form.useWatch(['items'], form)

  const items = useMemo(() => compact(rawItems || []), [rawItems])

  const mdBooinkgTypeId = Form.useWatch(['mdBooinkgTypeId'], form)
  const adminBookingRemark = Form.useWatch(['adminBookingRemark'], form)
  const customerName = Form.useWatch(['customerName'], form)
  const customerSurname = Form.useWatch(['customerSurname'], form)
  const selectedPromoCodes = Form.useWatch(['selectedPromoCodes'], form)

  const [isPromotionCodeVisible, setIsPromotionCodeVisible] = useState(false)

  const { data: userInfo } = useGetUserInfoQRY()

  const { mutateAsync: saveOrder } = useSaveOrderBookingFormAdminMTT()
  const { data: masterBookingTypes } = useGetMasterBookingTypeListQRY()

  const [isConfirmingOrder, setIsConfirmingOrder] = useState(false)

  const handleDeleteItem = useCallback(
    (item: ItemWithQty) => {
      const newItems = items?.filter((selected) => item.item.id !== selected.item.id)
      form.setFieldsValue({
        items: newItems,
      })
    },
    [form, items],
  )

  const summaryOrderItems = useMemo((): IOrderItemParams[] => {
    const results: IOrderItemParams[] = []
    if (items?.length) {
      results.push(...items.map((item) => covertItemWithQtyToOrder(item)))
    }

    if (slots?.length) {
      results.push(...slots.map((item) => convertSlotToOrder(item)))
    }

    return results
  }, [items, slots])

  const sumPrice = useMemo(() => {
    return calculateItemTotalPrice(summaryOrderItems)
  }, [summaryOrderItems])

  const totalPromotionCodeDiscountUnit = useMemo(() => {
    return chain(selectedPromoCodes ?? [])
      .map((e) => e?.discountUnit ?? 0)
      .sum()
      .defaultTo(0)
      .value()
  }, [selectedPromoCodes])

  const orderItemDiscount = useMemo(() => {
    const itemDiscount = items?.reduce(
      (sum, v) => sum + calculateDiscountPercent(v.item.discount, v.item.encoPrice, v.quantity),
      0,
    )
    const slotDiscount = orderSlotDesc(slots)
      .filter((slot, ind) => ind >= totalPromotionCodeDiscountUnit)
      .reduce((sum, v) => sum + v.discountAmt, 0)
    return itemDiscount + slotDiscount
  }, [items, slots, totalPromotionCodeDiscountUnit])

  const promoDiscount = useMemo(() => {
    let discount = 0
    const orderPriceSlots = orderSlotDesc(slots)
    orderPriceSlots.forEach((slot, ind) => {
      if (ind < totalPromotionCodeDiscountUnit) discount += slot.price
    })

    return discount
  }, [slots, totalPromotionCodeDiscountUnit])

  const credit = useMemo(() => {
    if (userInfo?.credit) {
      const priceDiffPromotion = sumPrice - orderItemDiscount - promoDiscount
      const userCredit = userInfo?.credit < 0 ? 0 : userInfo?.credit
      if (priceDiffPromotion < 0) return 0
      return userCredit <= priceDiffPromotion ? userCredit : priceDiffPromotion
    }
    return 0
  }, [promoDiscount, sumPrice, userInfo?.credit, orderItemDiscount])

  const net = useMemo(() => {
    const returnNet = sumPrice - credit - promoDiscount - orderItemDiscount
    return returnNet < 0 ? 0 : returnNet
  }, [credit, sumPrice, promoDiscount, orderItemDiscount])

  const discountDisplay = useMemo(() => {
    if (promoDiscount >= sumPrice) return sumPrice
    if (orderItemDiscount >= sumPrice) return sumPrice
    if (promoDiscount + orderItemDiscount >= sumPrice) return sumPrice
    return promoDiscount + orderItemDiscount
  }, [promoDiscount, sumPrice, orderItemDiscount])

  const bookingTypeOptions = useMemo<DefaultOptionType[]>(() => {
    return map(masterBookingTypes ?? [], (e) => ({
      label: e.bookingType,
      value: e.id,
    }))
  }, [masterBookingTypes])

  const handleClickConfirm = useCallback(async () => {
    setIsConfirmingOrder(true)
    try {
      await form.validateFields()
      await saveOrder(
        {
          id: 0,
          mdOrderTypeId: 1,
          tbServiceId: service.id,
          TbServicePropId: property?.id ?? 0,
          bookingdate: dayjs(date).format(apiDateFormat),
          MdReceiptTypeId: 1,
          mdServiceLocationId: null,
          credit: 0,
          // discount: promoDiscount + orderItemDiscount,
          discount: 0,
          total: sumPrice,
          paidAmt: sumPrice,
          orderItem: summaryOrderItems,
          tbPromoDtIds: selectedPromoCodes?.map((e) => e?.tbPromoDtId).join(',') ?? '',
          TbUserAddrId: null,

          firstname: '',
          lastname: '',
          email: '',
          tel: '',
          company: '',
          remark: '', //fix ยิง "
          locationDesc: '', //fix ยิง ""
          tbPromoDtId: null, //ยกเลิกใช้งานฟิลด์นี้
          net: 0, //fix เป็น 0
          mdBooinkgTypeId, //ได้จาก [GET] GetMasterBookingType เฉพาะ web admin
          adminBookingRemark,
          customerName,
          customerSurname,
        },
        {
          onError: () => {
            setIsConfirmingOrder(false)
          },
        },
      )

      window.location.replace(backendPaths.serviceOrderList({ routeParam: { serviceId: service.id } }))
    } catch (error) {
      form.submit()
      setIsConfirmingOrder(false)
    }
  }, [
    form,
    saveOrder,
    service.id,
    property?.id,
    date,
    sumPrice,
    summaryOrderItems,
    selectedPromoCodes,
    mdBooinkgTypeId,
    adminBookingRemark,
    customerName,
    customerSurname,
  ])

  const handleClickBack = useCallback(() => {
    onBack()
  }, [onBack])

  const handleClickPromotion = useCallback(() => {
    setIsPromotionCodeVisible(true)
  }, [])

  const handleClosePromotionModal = useCallback(() => {
    setIsPromotionCodeVisible(false)
  }, [])

  const handleSelectPromoCode = useCallback(
    (codes: IPromotionCodeResponse[]) => {
      form.setFieldsValue({
        selectedPromoCodes: codes,
      })
      form.validateFields(['selectedPromoCodes'])
    },
    [form],
  )

  const handleRemovePromoCode = useCallback(
    (code: IPromotionCodeResponse) => {
      const codes = selectedPromoCodes?.filter((e) => e.tbPromoDtId !== code.tbPromoDtId)
      form.setFieldsValue({
        selectedPromoCodes: codes ?? [],
      })
      form.validateFields(['selectedPromoCodes'])
    },
    [form, selectedPromoCodes],
  )

  const handlePricePerUnitChange = useCallback(
    (targetSlot: IGetServiceSlotSelectResponse) => (value: NumberFormatValues) => {
      const newSlots = slots.map((slot) => {
        if (targetSlot.mdSlotId === slot.mdSlotId) {
          slot.price = value.floatValue ?? 0
        }
        return slot
      })
      form.setFieldsValue({
        slots: newSlots,
      })
    },
    [form, slots],
  )

  return (
    <Container>
      {isPromotionCodeVisible && (
        <PromotionCodeModal
          selectedPromoCodes={selectedPromoCodes ?? []}
          onSelectCode={handleSelectPromoCode}
          serviceId={service.id}
          visible={isPromotionCodeVisible}
          onClose={handleClosePromotionModal}
        />
      )}

      <RowContainer>
        <Txt ag="header30">โค้ดส่วนลด</Txt>

        <Row>
          {selectedPromoCodes?.map((promotionCode) => (
            <Col key={promotionCode.promoCode}>
              <PromoCodeItem
                promoCode={promotionCode.promoCode}
                onRemove={handleRemovePromoCode.bind(null, promotionCode)}
              />
            </Col>
          ))}
        </Row>

        <LinkTextButton ag="body24" onClick={handleClickPromotion} color="lightBluePrimary">
          กรอกโค้ดส่วนลด
        </LinkTextButton>
      </RowContainer>

      <Row style={{ width: '100%', maxWidth: '600px' }}>
        <Col sm={24}>
          <Form.Item
            name="selectedPromoCodes"
            dependencies={['slots']}
            shouldUpdate
            rules={[
              ({ getFieldValue }) => {
                return {
                  validator: () => {
                    const _slots = getFieldValue('slots')
                    const _selectedPromoCodes = getFieldValue('selectedPromoCodes')
                    const totalSlots = _slots?.length
                    const _totalPromotionCodeDiscountUnit = chain(_selectedPromoCodes ?? [])
                      .map((e) => e?.discountUnit ?? 0)
                      .sum()
                      .defaultTo(0)
                      .value()
                    if (totalSlots && totalSlots < _totalPromotionCodeDiscountUnit) {
                      return Promise.reject('ไม่สามารถใช้รหัสโปรโมชันมากกว่าจำนวนที่จอง')
                    }

                    return Promise.resolve()
                  },
                }
              },
            ]}
          >
            <div />
          </Form.Item>
        </Col>
      </Row>

      {dataType === 'stadium' && (
        <div style={{ width: '100%', maxWidth: 600, marginTop: 12 }}>
          {slots?.map((slot) => {
            const startSplit = slot.slotIdStart.split(':')
            const endSplit = slot.slotIdEnd.split(':')
            const start = `${startSplit[0]}:${startSplit[1]}`
            const end = `${endSplit[0]}:${endSplit[1]}`
            const timeDisplay = `${start} - ${end}`
            const itemName = `${slot.itemNm} ${timeDisplay}`
            const discountText = slot.discountAmt > 0 ? `(ส่วนลด ${slot.discountAmt})` : ''

            return (
              <Row key={slot.mdSlotId} style={{ marginRight: '30px' }}>
                <Col span={24}>
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Txt ag="body24">
                        {itemName} {discountText}
                      </Txt>
                    </Col>
                    <Col>
                      <NumericFormat
                        value={slot.price}
                        inputMode="numeric"
                        decimalScale={0}
                        thousandSeparator=","
                        allowNegative={false}
                        allowLeadingZeros
                        onValueChange={handlePricePerUnitChange(slot)}
                        customInput={Input}
                        style={{ maxWidth: 100 }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Divider
                      style={{ margin: '12px 0', border: 'none', borderTop: `1px solid ${theme.color.grayBackground}` }}
                    />
                  </Row>
                </Col>
              </Row>
            )
          })}
        </div>
      )}

      <Form.List name="items">
        {(fields) => {
          return fields.map((field) => {
            return (
              <ItemsContainer key={field.key}>
                <Form.Item key={field.key} name={[field.name]} noStyle>
                  <ItemQty onDelete={handleDeleteItem} isShowTotalPrice editPricePerUnit />
                </Form.Item>
              </ItemsContainer>
            )
          })
        }}
      </Form.List>

      <TotalItemContainer>
        <Txt ag="header30" color="grayPrimaryText" className="summary-title">
          รวม
        </Txt>
        <Txt ag="header30" color="grayPrimaryText" className="summary-value">
          {numberFormat(sumPrice)} บาท
        </Txt>
        <Txt ag="header30" color="grayPrimaryText" className="summary-title">
          ส่วนลด
        </Txt>
        <Txt ag="header30" color="grayPrimaryText" className="summary-value">
          {numberFormat(discountDisplay)} บาท
        </Txt>
        <Txt ag="header30" color="grayPrimaryText" className="summary-title">
          เครดิต
        </Txt>
        <Txt ag="header30" color="grayPrimaryText" className="summary-value">
          {numberFormat(credit)} บาท
        </Txt>
        <Txt ag="header30" color="grayPrimaryText" className="summary-title">
          ยอดชำระทั้งหมด
        </Txt>
        <Txt ag="header30" color="grayPrimaryText" className="summary-value">
          {numberFormat(net)} บาท
        </Txt>
      </TotalItemContainer>

      <Row gutter={18}>
        <Col
          xs={{
            span: 24,
            order: 1,
          }}
          sm={{
            span: 12,
            order: 1,
          }}
        >
          <Form.Item name="mdBooinkgTypeId" label="ประเภทการจอง" rules={REQUIRED_RULES}>
            <Select options={bookingTypeOptions} />
          </Form.Item>
        </Col>
        <Col
          xs={{
            span: 24,
            order: 2,
          }}
          sm={{
            span: 12,
            order: 3,
          }}
        >
          <Form.Item name="adminBookingRemark" label="หมายเหตุ">
            <TextArea placeholder="หมายเหตุ" autoSize={{ minRows: 5, maxRows: 10 }} />
          </Form.Item>
        </Col>
        <Col
          xs={{
            span: 24,
            order: 3,
          }}
          sm={{
            span: 12,
            order: 2,
          }}
        >
          <Form.Item name="customerName" label="ชื่อลูกค้า" rules={REQUIRED_RULES} required>
            <Input />
          </Form.Item>
        </Col>

        <Col
          xs={{
            span: 24,
            order: 4,
          }}
          sm={{
            span: 12,
            order: 4,
          }}
        >
          <Form.Item name="customerSurname" label="นามสกุลลูกค้า" rules={REQUIRED_RULES} required>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <ButtonContainer>
        <CustomButton disabled={isConfirmingOrder} onClick={handleClickConfirm} type="primary">
          Confirm
        </CustomButton>
        <CustomButton onClick={handleClickBack}>Back</CustomButton>
      </ButtonContainer>
    </Container>
  )
}
