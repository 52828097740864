import { Col, notification, Row } from 'antd'
import { styled } from 'goober'
import { useCallback } from 'react'
import { FaRegCheckCircle } from 'react-icons/fa'

import { Txt } from 'components/Txt'
import { IGetServiceSlotSelectResponse, IServiceData } from 'services/enco-service/enco-service-response'
import theme from 'theme/goober'

const SlotButtonItem = {
  selected: {
    backgroundColor: theme.color.lightBluePrimary,
    border: `2px solid ${theme.color.lightBluePrimary}`,
    color: theme.color.white,
  },
  disabled: {
    backgroundColor: theme.color.grayText,
    border: `2px solid ${theme.color.grayText}`,
    color: theme.color.white,
  },
  default: {
    backgroundColor: theme.color.white,
    border: `2px solid ${theme.color.lightBluePrimary}`,
    color: theme.color.lightBluePrimary,
  },
}

interface SlotButtonContainerProps {
  ['background-color']: string
  border: string
  color: string
  disabled: boolean
}

const SlotButtonContainer = styled.button<SlotButtonContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  height: 36px;
  margin-left: 4px;
  border-radius: 30px;
  background-color: ${(props) => props['background-color']};
  border: ${(props) => props.border};
  color: ${(props) => props.color};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    opacity: 0.5;
  }
`

const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 100px;
  padding-right: 4px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-right: 1px solid ${theme.color.lightBluePrimary};
`

const SlotItemContainer = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${theme.color.grayBackground};
  padding: 4px 8px;
`

const Icon = styled(FaRegCheckCircle)`
  left: 8px;
  position: absolute;
  color: ${theme.color.white};
  cursor: pointer;
  font-size: 18px;
  margin-right: 12px;
`

interface SlotItemProps {
  slot: IGetServiceSlotSelectResponse
  state: keyof typeof SlotButtonItem
  onSelect: (value: IGetServiceSlotSelectResponse) => void
}

const SlotItem = (props: SlotItemProps) => {
  const { slot, state, onSelect } = props
  const startSplit = slot.slotIdStart.split(':')
  const endSplit = slot.slotIdEnd.split(':')
  const start = `${startSplit[0]}:${startSplit[1]}`
  const end = `${endSplit[0]}:${endSplit[1]}`
  const timeDisplay = `${start} - ${end}`
  const item = SlotButtonItem[state]

  const handleSelect = useCallback(() => {
    onSelect(slot)
  }, [onSelect, slot])

  return (
    <SlotItemContainer>
      <Col span={8}>
        <TimeContainer>{timeDisplay}</TimeContainer>
      </Col>
      <Col span={16} style={{ display: 'flex', justifyContent: 'center', minWidth: '200px' }}>
        <SlotButtonContainer
          disabled={state === 'disabled'}
          onClick={handleSelect}
          color={item.color}
          background-color={item.backgroundColor}
          border={item.border}
        >
          {state === 'selected' && <Icon />}
          {slot.txtDisplay}
        </SlotButtonContainer>
      </Col>
    </SlotItemContainer>
  )
}

const SlotSelectorContainer = styled('div')<{ error: string }>`
  height: 300px;
  min-width: 370px;
  overflow-y: scroll;
  border: 1px solid ${(props) => (props.error === 'yes' ? theme.color.antdError : theme.color.grayBackground)};
`

const DisabledContainer = styled('div')<{ error: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${theme.color.grayBackground};
  height: 300px;
  border: 1px solid ${(props) => (props.error === 'yes' ? theme.color.antdError : theme.color.grayBackground)};
`

interface SlotSelectorProps {
  service: IServiceData
  slots: IGetServiceSlotSelectResponse[]
  value?: IGetServiceSlotSelectResponse[]
  onChange?: (values: IGetServiceSlotSelectResponse[]) => void
  disabled?: boolean
  isError?: boolean
}

const validateSelectItem = (checkingItem: IGetServiceSlotSelectResponse[]) => {
  let isContinued = true
  checkingItem.forEach((selected, ind) => {
    if (ind !== 0) {
      if (selected.mdSlotId - 1 !== checkingItem[ind - 1].mdSlotId) isContinued = false
    }
  })
  return !isContinued
}

const isErrorString = (error: boolean) => (error ? 'yes' : 'no')

export const SlotSelector = (props: SlotSelectorProps) => {
  const { slots, disabled = false, onChange, isError = false, value: valueProps = [], service } = props

  const handleChange = useCallback(
    (values: IGetServiceSlotSelectResponse[]) => {
      onChange?.(values)
    },
    [onChange],
  )

  const findSlot = useCallback(
    (value: IGetServiceSlotSelectResponse) => {
      return valueProps?.find((selected) => selected.mdSlotId === value.mdSlotId)
    },
    [valueProps],
  )

  const mappingSelectedState = useCallback(
    (slot: IGetServiceSlotSelectResponse): keyof typeof SlotButtonItem => {
      if (slot.slotStatus === 0) return 'disabled'
      if (findSlot(slot)) return 'selected'
      return 'default'
    },
    [findSlot],
  )

  const handleSelect = useCallback(
    (value: IGetServiceSlotSelectResponse) => {
      const existed = findSlot(value)
      let newSelectedList = []
      if (existed) {
        newSelectedList = valueProps.filter((selected) => value.mdSlotId !== selected.mdSlotId)
      } else {
        newSelectedList = [...valueProps, value]
      }

      if (validateSelectItem(newSelectedList.sort((a, b) => a.mdSlotId - b.mdSlotId))) {
        notification.error({
          message: `ผิดพลาด`,
          description: 'กรุณาเลือกช่วงเวลาต่อเนื่องกัน',
          duration: 8,
        })
        return
      }
      handleChange(newSelectedList)
    },
    [valueProps, findSlot, handleChange],
  )

  return (
    <>
      {disabled ? (
        <DisabledContainer error={isErrorString(isError)}>
          <Txt ag="header28" color="grayPlaceholder">
            กรุณาเลือกช่วงเวลา
          </Txt>
        </DisabledContainer>
      ) : (
        <SlotSelectorContainer error={isErrorString(isError)}>
          {slots.map((slot) => (
            <SlotItem key={slot.mdSlotId} slot={slot} state={mappingSelectedState(slot)} onSelect={handleSelect} />
          ))}
        </SlotSelectorContainer>
      )}
      {isError && (
        <Txt ag="body20" color="antdError">
          กรุณาเลือกช่วงเวลาจอง{service.propUnitName}
        </Txt>
      )}
    </>
  )
}
