import { Form, Input } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { useGetServiceQRY } from 'services/enco-service/enco-service-service'

import { IBookingSummaryOrderProps, OrderDetailForm } from './OrderDetailForm'
import { OrderSummary } from './OrderSummary'

type OrderPageType = 'order' | 'summary'

export const PageOrderBooking = () => {
  const query = useParams()
  const serviceId = Number(query.serviceId)
  const { data: service } = useGetServiceQRY(serviceId)
  const [form] = Form.useForm<IBookingSummaryOrderProps>()

  const [page, setPage] = useState<OrderPageType>('order')

  const handleClickOrderContinue = useCallback(() => {
    setPage('summary')
    document.documentElement.scrollTo(0, 0)
  }, [])

  const handleClickSummaryBack = useCallback(() => {
    setPage('order')
    document.documentElement.scrollTo(0, 0)
  }, [])

  const initialValues = useMemo(
    (): IBookingSummaryOrderProps => ({
      date: '',
      items: [],
      slots: [],
    }),
    [],
  )

  if (!service) return null

  return (
    <>
      <ContentHeader title="Booking" />
      <ContentBody>
        <Form form={form} initialValues={initialValues} layout="vertical">
          {/* keep values */}
          <Form.Item name="date" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="items" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="slots" hidden>
            <Input />
          </Form.Item>
          {/* keep values */}
          <Form.Item hidden={page !== 'order'} noStyle>
            <OrderDetailForm service={service} onClickContinue={handleClickOrderContinue} />
          </Form.Item>
          <Form.Item hidden={page !== 'summary'} noStyle>
            <OrderSummary service={service} dataType="stadium" onBack={handleClickSummaryBack} />
          </Form.Item>
        </Form>
      </ContentBody>
    </>
  )
}
