import { Button, Card, Col, Divider, Form, notification, Row } from 'antd'
import { styled } from 'goober'
import { chain, compact } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import { ISearchAutoCompleteOption, SearchAutoComplete } from '_backend/components/SearchAutocomplete'
import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { REQUIRED_RULES } from '_backend/constants/form-rules'
import { Input, TextArea } from 'components/Input'
import { useModalConfirm } from 'components/ModalConfirmProvider'
import { Txt } from 'components/Txt'
import { numberFormat } from 'helpers/number-helper'
import { DashIfNull } from 'helpers/string-helper'
import { backendPaths } from 'paths/backend-path-config'
import { useGetUserDetailQRY, useGetUserListQRY, useUserTransferCreditMTT } from 'services/user/user-service'
import theme from 'theme/goober'

import { RowItem } from './UserDetailModal'

const ButtonGroupRow = styled(Row)`
  margin-bottom: 32px;
`

const ButtonOrange = styled(Button)`
  /* border-color: ${theme.color.orangePrimary};
  color: ${theme.color.orangePrimary}; */
`

interface IUserTransferCreditFromValues {
  senderUserId: number
  receiverUserId: number
  credit: number
  transferRemark: string
}

export const PageUserTransferCredit = () => {
  const navigate = useNavigate()
  const [query] = useSearchParams()
  const userId = query.get('userId') ?? ''

  const [form] = Form.useForm<IUserTransferCreditFromValues>()
  const receiverUserId = Form.useWatch('receiverUserId', form)

  const modalConfirm = useModalConfirm()
  const [txtSearch, setTxtSearch] = useState<string>()

  const { data: senderUser } = useGetUserDetailQRY(userId?.toString(), !!userId)
  const { data: receiverUser } = useGetUserDetailQRY(receiverUserId?.toString(), !!receiverUserId)
  const { data: userList = [], isLoading: isSearchUsersLoading } = useGetUserListQRY({
    txtSearch,
  })
  const { mutate: transferCredit } = useUserTransferCreditMTT()

  const initialValues = useMemo(
    (): Partial<IUserTransferCreditFromValues> => ({
      senderUserId: +userId,
    }),
    [userId],
  )

  const userOptions = useMemo(
    () =>
      chain(userList ?? [])
        .filter((e) => e.userid !== +userId)
        .map((e): ISearchAutoCompleteOption => {
          const text = compact([e.prefix, e.firstname, e.lastname, e.email]).join(' ')
          return {
            value: e.userid,
            text,
          }
        })
        .value(),
    [userList, userId],
  )

  const senderUserName = useMemo(() => {
    if (!senderUser) return

    const { prefix, firstname, lastname } = senderUser
    return `${prefix}${firstname} ${lastname}`
  }, [senderUser])

  const receiverUserName = useMemo(() => {
    if (!receiverUser) return

    const { prefix, firstname, lastname } = receiverUser
    return `${prefix}${firstname} ${lastname}`
  }, [receiverUser])

  const handleSaveSuccess = useCallback(() => {
    navigate(backendPaths.userList())
    notification.success({ message: 'สำเร็จ', description: 'บันทึกข้อมูลสำเร็จ', duration: 2 })
  }, [navigate])

  const handleSubmitForm = useCallback(
    async (values: IUserTransferCreditFromValues) => {
      modalConfirm.show({
        type: 'default',
        title: 'ยืนยันที่จะโอน Credit',
        content: 'คุณต้องการยืนยันที่จะโอน Credit หรือไม่?',
        modalProps: {
          width: 700,
        },
        onOk: async () => {
          if (receiverUser && senderUser) {
            await transferCredit(
              {
                CreditFrom: values.senderUserId,
                CreditTo: values.receiverUserId,
                Credit: senderUser?.credit ?? 0,
                TransferRemark: values.transferRemark,
              },
              { onSuccess: handleSaveSuccess },
            )
          }
        },
      })
    },
    [modalConfirm, receiverUser, senderUser, transferCredit, handleSaveSuccess],
  )

  const onSearchUser = useCallback((value: string) => {
    setTxtSearch(value)
  }, [])

  // const onSelectUser = useCallback(
  //   (empcode: string) => {
  //     const employeeHash = keyBy(userList, 'empcode')
  //     const employee = employeeHash[empcode]
  //     if (employee) {
  //       form.setFieldsValue({
  //         prefix: employee.prefix,
  //         firstname: employee.firstname,
  //         lastname: employee.lastname,
  //         empcode: employee.empcode,
  //         email: employee.email,
  //         tel: employee.tel,
  //         profile: employee.profile,
  //         searchEmployee: '',
  //       })
  //     }
  //     setTxtSearch('')
  //   },
  //   [userList, form],
  // )

  return (
    <>
      <ContentHeader subTitle="โอน Credit" />
      <ContentBody max-width="686px">
        <Card>
          <Form layout="vertical" form={form} initialValues={initialValues} onFinish={handleSubmitForm}>
            <Row>
              <Col span={24}>
                {/* keep values */}
                <Form.Item name="senderUserId" hidden>
                  <Input />
                </Form.Item>
                {/* keep values */}
                <Form.Item label="ค้นหาผู้ใช้งาน เพื่อโอน Credit" name="receiverUserId">
                  <SearchAutoComplete
                    placeholder="ค้นหาจากชื่อโปรไฟล์ / ชื่อ - นามสกุล / email "
                    onSearch={onSearchUser}
                    options={userOptions}
                    isLoading={isSearchUsersLoading}
                    width="100%"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Txt ag="header28">From</Txt>
            </Row>
            <Row>
              <RowItem label="ชื่อ - นามสกุล" value={DashIfNull(senderUserName)}></RowItem>
              <RowItem label="Credit" value={`${numberFormat(senderUser?.credit ?? 0, 2)} บาท`}></RowItem>
            </Row>
            <Row>
              <Divider style={{ marginTop: 0 }} />
            </Row>

            {receiverUser && (
              <>
                <Row>
                  <Txt ag="header28">To</Txt>
                </Row>
                <Row>
                  <RowItem label="ชื่อ - นามสกุล" value={DashIfNull(receiverUserName)}></RowItem>
                </Row>
                <Row>
                  <RowItem label="อีเมล" value={DashIfNull(receiverUser?.email)}></RowItem>
                  <RowItem label="เบอร์โทรศัพท์" value={DashIfNull(receiverUser?.tel.toString())}></RowItem>
                </Row>
                <Row>
                  <RowItem label="กลุ่มผู้ใช้งาน" value={DashIfNull(receiverUser?.userGrp)}></RowItem>
                  <RowItem label="ชื่อโปรไฟล์" value={DashIfNull(receiverUser?.profile)}></RowItem>
                </Row>
                <Row>
                  <RowItem label="Account Type" value={DashIfNull(receiverUser?.accountType)}></RowItem>
                  <RowItem label="Logon" value={DashIfNull(receiverUser?.logon)}></RowItem>
                </Row>
                <Row>
                  <RowItem
                    label="สถานะผู้ใช้งาน (Status)"
                    value={receiverUser?.active ? 'Active' : 'Inactive'}
                  ></RowItem>
                  <RowItem label="Credit" value={`${numberFormat(receiverUser?.credit ?? 0, 2)} บาท`}></RowItem>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item label="หมายเหตุ" name="transferRemark" rules={REQUIRED_RULES} required>
                      <TextArea
                        value={receiverUser?.delAccReason}
                        rows={3}
                        autoSize={{ minRows: 3, maxRows: 3 }}
                        showCount={false}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Divider style={{ marginTop: 0 }} />
                </Row>
              </>
            )}

            <Row align="middle" justify="space-between">
              <Col>
                <Link to={backendPaths.userList()} replace>
                  <Button>กลับ</Button>
                </Link>
              </Col>
              <Col>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    return (
                      <Button type="primary" htmlType="submit" disabled={!getFieldValue('receiverUserId')}>
                        บันทึกโอน Credit
                      </Button>
                    )
                  }}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </ContentBody>
    </>
  )
}
